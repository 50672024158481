import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'
import { MenuController, AlertController, ToastController, Platform } from '@ionic/angular'
import { SettingsService } from '../settings/settings.service'

@Injectable({
    providedIn: 'root'
})
export class Dialogues {
    constructor(
        public translate: TranslateService,
        public toastController: ToastController,
        public alertController: AlertController,
        private settings: SettingsService) {

    }
    async showAlert(header, message, handler = () => { }) {
        const alert = await this.alertController.create({
            header: header,
            message: message,
            cssClass: 'language-alert',
            buttons: [
                {
                    text: "OK",
                    cssClass: 'alert-button',
                    handler
                }
            ]
        });
        await alert.present()
    }

    //Translatable dialog
    // vars[0]: Header lang key, vars[1]: Message lang key
    // params: values replacement
    // ok: confirmation callback
    // Example: this.showTransAlert(['PROFILE.MSG_ATTENTION', 'PROFILE.MSG_EXTENSION_REQUIRED'], { provider: 'Researchgate' });
    async showTransDialog(ok, vars, params = []) {
        this.translate.get([...vars, 'YES', 'NO'], params).subscribe(async (res: []) => {
            const alert = await this.alertController.create({
                header: res[vars[0]],
                message: res[vars[1]],
                cssClass: 'language-alert',
                buttons: [{
                    text: res['YES'],
                    cssClass: 'alert-button',
                    handler: ok
                },
                {
                    text: res['NO'],
                    cssClass: 'alert-button',
                    handler: () => { }
                }]
            });
            await alert.present();
        });
    }

    /**
     * 
     * @param vars    [Header lang key, Message lang key]
     * @param params  Values replacement
     * @param handlerNo Callback function in Calcel case
     * @param handlerYes Callback function in Confirm case
     * @usageNotes    this.showTransAlert(['PROFILE.MSG_ATTENTION', 'PROFILE.MSG_EXTENSION_REQUIRED'], 
     * { provider: 'Researchgate' }, ()=>{console.log('Dialog canceled')}, ()=>{console.log('Dlg confirmed')});
     */
    async showTransConfirmation(vars, params = {}, handlerNo = () => { }, handlerYes = () => { }) {
        this.translate.get(vars, params).subscribe(async (res: []) => {
            const alert = await this.alertController.create({
                header: res[vars[0]],
                message: res[vars[1]],
                cssClass: 'language-alert',
                buttons: [{
                    text: "Cancel",
                    role: 'cancel',
                    cssClass: 'alert-button',
                    handler: handlerNo
                }, {
                    text: "OK",
                    cssClass: 'alert-button',
                    handler: handlerYes
                },]
            });
            await alert.present();
        });
    }

    /**
     * 
     * @param vars    [Header lang key, Message lang key]
     * @param params  Values replacement
     * @param handler Callback function
     * @usageNotes    this.showTransAlert(['PROFILE.MSG_ATTENTION', 'PROFILE.MSG_EXTENSION_REQUIRED'], { provider: 'Researchgate' }, ()=>{console.log('Dlg shown')});
     */
    async showTransAlert(vars, params = {}, handler = () => { }) {
        let guid = params['guid']
        if (!guid || guid && !this.settings.params.notices[guid])
            this.translate.get(vars, params).subscribe(async (res: []) => {
                const alert = await this.alertController.create({
                    header: res[vars[0]],
                    message: res[vars[1]],
                    cssClass: 'language-alert',
                    buttons: [{
                        text: "OK",
                        cssClass: 'alert-button',
                        handler
                    }]
                });
                if (guid)
                    this.settings.setParam({ notices: { [guid]: true } })
                await alert.present();
            });
        else handler()
    }

    /**
    * Error handler
    * 
    * @param vars    [Header lang key, Message lang key]
    * @param params  Values replacement
    * @param handler Callback function
    * @usageNotes    this.showTransAlert(['PROFILE.MSG_ATTENTION', 'PROFILE.MSG_EXTENSION_REQUIRED'], { provider: 'Researchgate' }, ()=>{console.log('Dlg shown')});
    */
    async errorMessage(error, handler = () => { }) {
        console.log(error)
        this.translate.get(["ERROR.ERROR_HEADER"]).subscribe(async (translate: []) => {
            const alert = await this.alertController.create({
                header: translate["ERROR.ERROR_HEADER"],
                subHeader: error.code,
                message: error.message,
                cssClass: 'language-alert',
                buttons: [{
                    text: "OK",
                    cssClass: 'alert-button',
                    handler
                }]
            });
            await alert.present();
        });
    }

    /**
     * Show translated toast message
     * 
     * @param message Message lang key
     * @param params Values replacement
     */
    async noticeUser(message, params = {}) {
        this.translate.get([message], params).subscribe(async (res: []) => {
            const toast = await this.toastController.create({
                message: res[message] || message,
                duration: 2000
            });
            toast.present();
        })
    }

}