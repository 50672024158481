import { InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx'
export class SettingsModel {
    userId: string
    devmode: boolean
    alteregoOn: boolean
    adminId: string
    alteregoId: string
    email: string
    similarity: number
    language: string | null
    itabConf: InAppBrowserOptions
    baseHref: string
    params:{
        notices: {
            [id: string]: boolean
        }
    }
}