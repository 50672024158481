import { ComparePublicationIDs } from '../../compare/compare.model'
export class ResearchgateModel {
  accountID: string
  changed?: string
  avatar?: string
  displayName?: string
  firstname?: string
  middlename?: string
  lastname?: string
  keywords?: string
  degree?: string
  affiliation?: {
    professionalInstitution?: string
    professionalDepartment?: string
    professionalPosition?: string
  }
  email?: string
  aboutme?: string
  publications?: { [pubid: string]: ResearchgatePublication }
  profileurl?: string
  indexes?: {
    h_all: number,
    citations_all: number
  }
}

export class ResearchgatePublication {
  ids?: ComparePublicationIDs
  title: string
  translated_title?: string
  type?: number
  link?: string
  authors?: ResearchgateAuthors[]
  pages?: string
  publicationDate?: string
  abstract?: string
  keywords?: Array<string>
}

export class ResearchgateAuthors {
  id: string
  fullName: string
}

export class ResearchgatePublicationID {
  id_type: string
  id_value: string
}