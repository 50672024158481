// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  // rootPath: './' - for local revision
  root: '/',
  firebase: {
    apiKey: 'AIzaSyCrsgVzvB_rGNBNCC7TwoITArh9AHnJv_w',
    authDomain: 'web.rewrite.today',
    databaseURL: 'https://web.rewrite.today',
    projectId: 'rewrite-today',
    storageBucket: 'web.rewrite.today',
    messagingSenderId: '779559003911',
    appId: "1:779559003911:web:bc564bb7ad09051a79c7a2"
  },
  appShellConfig: {
    debug: false,
    networkDelay: 500
  },
  extention: {
    Id: "jkomehibhlelnecdmgiohdabldnlhmdf",
    MinVer: "0.0.7"
  },
  backend: {
    url: 'https://rewrite.today/bgs1'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
