//https://domske.github.io/state-stepper/
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { ProfileService } from '../../profile/profile.service'
import { ProvidersService } from '../../providers/providers.service'

@Component({
    selector: 'app-stepper-component',
    templateUrl: './stepper.component.html',
    styleUrls: [
        './stepper.component.scss'
    ]
})
export class StepperComponent implements OnInit {
    constructor(
        public profileService: ProfileService,
        public providers: ProvidersService,
        public router: Router
    ) {

    }
    stateStepper
    step
    ngOnInit(): void {
        this.stateStepper = document.querySelector('state-stepper');
        //this.step = this.profileService.
    }
    stepNumber = 0
    steps = {
        0: {
            path: '/profile',
            back: false
        },
        1: {
            path: '/analyse',
            back: true
        },
    }
    nextStep() {
        this.stateStepper.stepNext('success');
        this.stepNumber++
        console.log(this.stepNumber)
        this.router.navigate([this.steps[this.stepNumber].path], { replaceUrl: true })
    }
    prevStep(){
        this.stateStepper.stepBack();
        this.stepNumber--
        console.log(this.stepNumber)
        this.router.navigate([this.steps[this.stepNumber].path], { replaceUrl: true })
    }
}