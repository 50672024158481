<state-stepper>
  {{stepNumber}}
  <ion-button class="ion-margin-vertical" *ngIf="steps[stepNumber].back" (click)="prevStep()">Previouse</ion-button>
    <state-stepper-step state="active">
      <ion-icon name="options" slot="icon"></ion-icon>
      <span slot="text">Link providers</span>
    </state-stepper-step>
    <state-stepper-step>
      <ion-icon name="settings" slot="icon"></ion-icon>
      <span slot="text">Fix</span>
    </state-stepper-step>
    <state-stepper-step>
      <ion-icon name="cloud-upload" slot="icon"></ion-icon>
      <span slot="text">Analyze</span>
    </state-stepper-step>
    <state-stepper-step>
      <ion-icon name="save" slot="icon"></ion-icon>
      <span slot="text">Save</span>
    </state-stepper-step>
    <state-stepper-step>
      <ion-icon name="checkmark" slot="icon"></ion-icon>
      <span slot="text">Complete</span>
    </state-stepper-step>
    <ion-button class="ion-margin-vertical" [disabled]="providers.linkedProovidersCount().linked < 2" (click)="nextStep()">Next</ion-button>
</state-stepper>