import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ProviderService } from '../provider.service'
import { ProfileService } from '../../profile/profile.service';
import { FacebookModel } from './facebook.model'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Utils } from '../../components/utils'
import { environment } from '../../../environments/environment'

@Injectable()
export class FacebookService extends ProviderService {
  id = 'facebook'
  name = "Facebook"
  topPic = `${this.baseHref}assets/providers/${this.id}/topPic.png`
  icon = "logo-facebook"
  site = "facebook.com"
  userPic = ''
  data: FacebookModel
  location: Location
  //Special rulle to get the avatar
  //TODO add token
  userPicTmpl = () => { return this?.user?.uid ? 'https://graph.facebook.com/' + this?.user?.uid + '/picture?type=large&access_token=' + this?.auth?.token : '' }
  scopes = {                              //API interface constant from provider
    auth: 'openid'
  }
  constructor(
    public profileService: ProfileService,
    public iab: InAppBrowser,
    location: Location,
    private router: Router
  ) {
    super(
      profileService,
    )
    this.location = location
  }

  success = false
  getToken(token: string, callBack = (e) => { }) {
    this.success = false
    this.profileService.browserExtention.actionInProgress = true
    if (this.profileService.platform.isNative) {
      //Android model in Webview
      const browser = this.iab.create(`${environment.backend.url}/auth?provider=${this.id}&variation=loc&utoken=${token}&scope=${this?.scopes?.auth}`, '_blank', this.itabConf);
      browser.on('loadstart').subscribe(event => {
        console.log('event', event)
        let e = /token=([^\&]*)/.exec(event.url)
        let token = e && e.length > 0 ? e[1] : ''
        //let id = /google=([^\&]*)/.exec(event.url)[1];
        e = /google=([^\&]*)/.exec(event.url)
        let id = e && e.length > 0 ? e[1] : ''
        this.user = { uid: id }
        let error = /error=(.+)$/.exec(event.url);
        if (token) {
          this.auth = {
            token,
            id,
            tokenall: { ...event }
          }
          this.success = true
          this.store()
          this._loading = false
          this.profileService.browserExtention.actionInProgress = false
        }
        else if (error) callBack(error);
        if (token || error) browser.close()
      })
      browser.on('exit').subscribe(event => {
        console.log('Browser Exit fired')
        this.profileService.browserExtention.actionInProgress = false
        if (this.success)
          this.profileService.dlg.noticeUser('PROVIDERS.DATA_RECEIVED', { provider: this.name })
        else
          this.profileService.dlg.noticeUser('PROVIDERS.ACTION_CANCELED')
        browser.close()
      })
      browser.on('loaderror').subscribe(event => {
        let e = /token=([^\&]*)/.exec(event.url)
        let token = e && e.length > 0 ? e[1] : ''
        let error = /error=(.+)$/.exec(event.url);
        if (token) {
          this.auth = {
            token,
            id: '',
            tokenall: { ...event }
          }
          this.profileService.dlg.noticeUser('PROVIDERS.DATA_RECEIVED', { provider: this.name })
          this.store()
          this._loading = false
          this.profileService.browserExtention.actionInProgress = false
        }
        if (token || error) browser.close()
      })
    } else {
      window.location.href = `${environment.backend.url}/auth-web?provider=${this.id}&variation=srv&utoken=${token}&scope=${this?.scopes?.auth}`;
    }
  }

  saveToken(token: string, tokenall: Record<string, string>): void {
    this.auth = {
      token,
      id: tokenall?.orcid,
      tokenall,
      created: Utils.makeDate().toISOString()
    }
    this.profileService.browser.getData('https://graph.facebook.com/me?fields=first_name,email,id,middle_name,name,short_name,age_range,name_format,last_name,link&access_token=' + this.auth.token)
      .then((result: FacebookModel) => {
        console.log(result)
        this.data = result
        this.changed = Utils.makeDate().toISOString()
        this.user = {
          uid: this.data.id,
          displayName: this.data.name,
          email: this.data.email,
          profileUrl: this.data.link,
        }
        this.auth.id = this.data.id
        this._loading = false
        this.profileService.browserExtention.actionInProgress = false
        this.profileService.dlg.noticeUser('PROVIDERS.DATA_RECEIVED', { provider: this.name }).catch((e) => console.log('Error noticeUser', e))
        this.store(() => { })
        this.router.navigate(["/profile"]).catch((rez) => console.log('Navigate error', rez));
      })
      .catch((e) => {
        this._loading = false
        this.profileService.browserExtention.actionInProgress = false
        console.log('Error getData', e)
      })
  }

  getData() {
    this.profileService.browser.getData('https://graph.facebook.com/me?fields=first_name,email,id,middle_name,name,short_name,age_range,name_format,last_name,link&access_token=' + this.auth.token)
      .then((result: FacebookModel) => {
        console.log(result)
        this.data = result
        this.data.changed = Utils.makeDate().toISOString()
        this.changed = Utils.makeDate().toISOString()
        this.user = {
          uid: this.data.id,
          displayName: this.data.name,
          email: this.data.email,
          profileUrl: this.data.link,
        }
        this.auth.id = this.data.id
        this._loading = false
        this.profileService.browserExtention.actionInProgress = false
        this.profileService.dlg.noticeUser('PROVIDERS.DATA_RECEIVED', { provider: this.name }).catch((e) => console.log('Error noticeUser', e))
        this.store(() => { })

      })
      .catch((e) => {
        this.profileService.dlg.showTransConfirmation(['PROFILE.LOGIN_REQUIRED'], {},
          () => {
            this._loading = false
            this.profileService.browserExtention.actionInProgress = false
            console.log('Error getData', e)
          },
          () => {
            this.getToken('')
          })
      })
  }

  loadCompareModel = () => {
    let data = this.data
    if (data)
      this.compareFields = {
        photoURL: this.userPic,
        displayName: data.name,
        email: data.email,
        firstname: data.first_name,
        lastname: data.last_name
      }
  }
}