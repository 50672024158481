import { Injectable } from '@angular/core'
import { ProviderService } from '../provider.service'
import { ProfileService } from '../../profile/profile.service'
import { ScopusModel, ScopusPublication } from './scopus.model'
import { RemoteAction } from '../provider.model'

import { environment } from '../../../environments/environment'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { CompareModel, CompareFieldsModel, ComparePublicationIDs } from '../../compare/compare.model'
import { Utils } from '../../components/utils'

@Injectable()
export class ScopusService extends ProviderService {
  id = 'scopus'
  name = "Scopus"
  topPic = `${this.baseHref}assets/providers/${this.id}/topPic.png`
  icon = "aperture-outline"
  site = "scopus.com"
  userPic = ''
  APIkey = '5eacbc6382a45cecf43b4ff4014c37d9'
  data: ScopusModel

  /**
  * Index based on Google Scholar
  * 0 - journal
  * 1 - book
  * 3 - conference
  * 4 - chapter
  * 5 - book-review - ORCID
  * 8 - other
  * 9 - thesis
  * 12 - patent
  * 17 - courtcase
  */

  getPubTypeIndex = {
    'article': 0,
    'book': 1,
    'inCollection': 4,  //Use RG key
    'chapter': 4,       //Use RG value
    'code': 8,
    'inProceedings': 3,
    'conference-paper': 3,  //use RG value. Lowercase, replace space to dash
    'coverPage': 8,         //key
    'cover-page': 8,        //value. Lowercase, replace space to dash
    'dataset': 8,           //key
    'data': 8,              //value
    'experimentFindings': 8,  //key
    'experiment-findings': 8, //value
    'method': 8,
    'negativeResults': 8,     //key
    'negative-results': 8,    //value
    'patent': 12,
    'poster': 8,
    'preprint': 0,
    'presentation': 8,
    'rawData': 8,             //key
    'raw-data': 8,            //value
    'researchProposal': 8,
    'research-proposal': 8,
    'technicalReport': 3,
    'technical-report': 3,
    'thesis': 9
  }

  constructor(
    public profileService: ProfileService,
    public iab: InAppBrowser
  ) {
    super(
      profileService,
    )
    this.data = new ScopusModel
    //Store data from extantion
    this.profileService.browserExtention.hostActions['scGetData'] = () => {
      let result = this.profileService.browserExtention.extentionData
      console.log(result)
      //TODO update only incoming data. Keep existing data
      this.user = {
        uid: result['userid'] || '',
        displayName: result['name'] || '',
        photoURL: result['avatar'] || '',
        profileUrl: result['profileurl'] || ''
      }
      this.auth = {
        token: result['userid'] || '',
        created: Utils.makeDate().toISOString(),
        tokenall: {}
      }
      let accountID = result['accountID']
      //publications
      /**
       * Copy old values
       * Find new record in old list and copy to new object
       * If none create new object and add to the new object
       * replace old data with new object (by properties)
       * TODO mark new and save deleted data
       */
      let oldPubData = {}
      if (this.data?.publications)
        oldPubData = JSON.parse(JSON.stringify(this.data?.publications))
      let newPubData: { [pubid: string]: ScopusPublication } = {}
      if (!this.data?.publications)
        this.data['publications'] = {}
      //if have old data: Store key: ids
      if (result['pubdetails']) {
        let new_IDs: ComparePublicationIDs
        Object.entries(result['pubdetails']).forEach(([newPublicationID, newPublicationData]) => {
          let curID = Utils.randomKey()
          new_IDs = {
            rgpid: newPublicationData['id'] || '',
            doi: newPublicationData['doi'] || '',
            isbn: newPublicationData['isbn'] || '',
            issn: newPublicationData['issn'] || ''
          }
          Object.entries(this.data.publications).forEach(([oldPublicationID, oldPublicationData]) => {
            if (oldPublicationData?.ids && oldPublicationData?.ids == new_IDs.rgpid)
              curID = oldPublicationID
          })

          newPubData[curID] = {
            title: newPublicationData['title'] || '',
            type: this.getPubTypeIndex[newPublicationData['concreteType'].name] || 0,
            //authors?: ScopusAuthors[]
            pages: newPublicationData['pages'] || '',
            publicationDate: newPublicationData['publicationDate'] || '',
            abstract: newPublicationData['abstract'] || '',
            keywords: newPublicationData['keywords'] || [],
            ids: { ...this.data.publications[curID], ...new_IDs }
          }
        })
      } else { //Keep olda data if none incoming
        newPubData = oldPubData
      }
      this.data = {
        ...this.data,
        ...{
          accountID: accountID,
          displayName: result['name'] || '',
          firstname: result['account_names']?.firstname || '',
          middlename: result['account_names']?.middlename || '',
          lastname: result['account_names']?.lastname || '',
          aboutme: (result['aboutme']) ? result['aboutme'][accountID] || '' : '',
          degree: result['account_names'].degree || '',
          affiliation: result['affiliation'],
          publications: newPubData
        }
      }
      this.data.changed = Utils.makeDate().toISOString()
      console.log(this.data)
      this.store()
    }
  }

  loadCompareModel = () => {
    let data = this.data
    this.compareFields = {}
  }

  getToken(token: string, then) {
    if (this.profileService.platform.isNative) {
      //Android model in Webview
      const browser = this.iab.create(`${environment.backend.url}/auth?provider=${this.id}&variation=loc&utoken=${token}&scope=${this?.scopes?.auth}`, '_blank', this.itabConf);
      browser.on('loadstart').subscribe(event => {
        let token = /token=([^\&]*)/.exec(event.url)[1];
        let id = /orcid=([^\&]*)/.exec(event.url)[1];
        let error = /error=(.+)$/.exec(event.url);
        this.user = { uid: id[1] }
        if (token) {
          this.auth = {
            token,
            id,
            tokenall: { ...event }
          }
          this.store()
          this._loading = false
          this.profileService.browserExtention.actionInProgress = false
        }
        else if (error) then(error);
        if (token || error) browser.close()
      })
      browser.on('loaderror').subscribe(event => {
        let token = /token=([^\&]*)/.exec(event.url)[1];
        let id = /orcid=([^\&]*)/.exec(event.url)[1];
        let error = /error=(.+)$/.exec(event.url);
        this.user = { uid: id }
        if (token) {
          this.auth = {
            token,
            id: id,
            tokenall: { ...event }
          }
          this.profileService.dlg.noticeUser('PROVIDERS.DATA_RECEIVED', { provider: this.name })
          this.store()
          this._loading = false
          this.profileService.browserExtention.actionInProgress = false
        }
        if (token || error) browser.close()
      })
    } else {
      window.location.href = `${environment.backend.url}/auth-web?provider=${this.id}&variation=srv&utoken=${token}&scope=${this?.scopes?.auth}`;
    }
  }

  getData() {
    this.remoteActions = [{
      id: 'scGetData',
      url: 'https://researchgate.net',
      data: ''
    }, {
      id: 'scGetAPIData',
      url: 'https://researchgate.net',
      data: ''
    }
    ]
    this.getFromWeb(this.remoteActions, 'scGetData')
  }

}