import { NgModule } from '@angular/core'
import { ProviderService } from './provider.service'
import { OrcidService } from './orcid/orcid.service'
import { FacebookService } from './facebook/facebook.service'
import { GoogleService } from './google/google.service'
import { ResearchgateService } from './researchgate/researchgate.service'
import { ScholarService } from './scholar/scholar.service'
import { PublonsService } from './publons/publons.service'
import { ScopusService } from './scopus/scopus.service'
import { ElibraryService } from './elibrary/elibrary.service'
import { ProviderModule } from './provider.module'

@NgModule({
    imports: [
        ProviderModule
    ],
    declarations: [
    ],
    providers: [
        OrcidService,
        FacebookService,
        GoogleService,
        ResearchgateService,
        ScholarService,
        PublonsService,
        ScopusService,
        ElibraryService
    ]
})
export class ProvidersModule { }
