import { Injectable } from '@angular/core';
import { LanguageModel } from './language.model';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { SettingsService } from '../settings/settings.service'

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  languages: Array<LanguageModel> = new Array<LanguageModel>();
  curLang: string
  translations: { [key: string]: string }
  textDir: string
  constructor(
    public translate: TranslateService,
    public settings: SettingsService) {
    this.curLang = this.translate.getBrowserLang() || 'en'
    this.languages.push(
      { name: 'English', code: 'en' },
      { name: 'Русский', code: 'ru' }
      //      { name: 'Spanish', code: 'es' },
      //      { name: 'French', code: 'fr' },
      //      { name: 'Arabic', code: 'ar' }
    );

  }

  getLanguages() {
    return this.languages;
  }

  initLang() {
    return this.settings.storage.load('-').then(profile => this.setLanguage(profile["language"]))
  }

  setLanguage(lang) {
    if (!lang) lang = this.curLang
    this.curLang = lang
    this.textDir = (lang === 'ar' || lang === 'iw') ? 'rtl' : 'ltr';
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(lang); //TODO get language fron Android and iOS http://docs.phonegap.com/en/3.0.0/cordova_globalization_globalization.md.html#Globalization

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(lang);
    this.translate.getTranslation(this.curLang)
      .subscribe((translations) => {
        this.translations = translations
      })
    this.settings.storage.set({ language: lang }, '-')
    this.settings.language = lang
  }

}
