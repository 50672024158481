<ion-app dir="{{language.textDir}}">
    <!-- when=false means that the split pane will never expand automatically -->
    <!-- For more info check https://ionicframework.com/docs/api/split-pane -->
    <ion-split-pane contentId="menu-content" when="lg">
        <ion-menu contentId="menu-content" class="app-sidemenu">
            <ion-header>
                <ion-toolbar>
                    <ion-row class="user-details-wrapper">
                        <ion-col size="4">
                            <app-aspect-ratio [ratio]="{w: 1, h: 1}">
                                <app-image-shell [routerLink]="'/profile/'" class="user-avatar" animation="spinner"
                                    [src]="'./assets/rt-images/logo_400.jpg'"></app-image-shell>
                            </app-aspect-ratio>
                        </ion-col>
                        <ion-col class="user-info-wrapper" size="8">
                            <h3 class="user-name">{{ providers.list[0]?.user?.displayName }}</h3>
                            <h5 class="user-handle" *ngFor="let provider of getIndexes()" title="{{provider.name}}">
                                <ion-icon [name]="provider.icon? provider.icon: ''"
                                    [src]="provider.customIcon? provider.customIcon: ''"></ion-icon>
                                h: <b>{{ provider.compareFields.indexes.h_all}}</b>,
                                pubs: <b>{{pubsCount(provider)}}</b>
                                cit: <b>{{provider.compareFields.indexes.citations_all}}</b>
                            </h5>
                        </ion-col>
                    </ion-row>
                    <ion-row class="user-stats-wrapper user-details-wrapper">
                        <ion-col>
                            <span class="user-stat-value">{{providers.linkedProovidersCount().linked}}</span>
                            <span class="user-stat-name">{{ 'PROFILE.PROVIDERS_COUNT_TITLE' | translate}}</span>
                        </ion-col>
                        <ion-col>
                            <span class="user-stat-value">{{providers.syncStatus()}}%</span>
                            <span class="user-stat-name">{{'PROFILE.PROVIDERS_SYNCRONIZED_TITLE'| translate}}</span>
                        </ion-col>
                    </ion-row>
                </ion-toolbar>
            </ion-header>
            <ion-content>
                <ion-list>
                    <ion-menu-toggle autoHide="false" *ngFor="let p of appPages; let i = index">
                        <ion-item [routerLink]="p.url" routerLinkActive="active-link">
                            <ion-icon slot="start" [name]="p.ionicIcon? p.ionicIcon: ''"
                                [src]="p.customIcon? p.customIcon: ''"></ion-icon>
                            <ion-label>
                                {{p.title | translate}}
                            </ion-label>
                        </ion-item>
                        <ion-list *ngIf="p.id == 0" style="margin-left: 20px;">
                            <!--ion-list-header>
                                <ion-label>{{ 'PROFILE.COMPARE' | translate}}</ion-label>
                            </ion-list-header-->
                            <ion-menu-toggle autoHide="false" *ngFor="let provider of providers.list">
                                <ion-item *ngIf="provider?.data && provider?.auth" [routerLink]="'/tabs/compare/'+provider.id"
                                    [class.active-link]="provider.id==providers.selectedProvider">
                                    <ion-icon slot="start" [name]="provider.icon? provider.icon: ''"
                                        [src]="provider.customIcon? provider.customIcon: ''"></ion-icon>
                                    <ion-label>
                                        {{provider.name}}
                                    </ion-label>
                                    <div [title]="'COMPARE.CHANGED_UNSAVED' | translate"
                                        *ngIf="providers.providerHasUnsavedChanges(provider.id) > 0">
                                        <ion-icon name="medical-outline" color="seconday"></ion-icon>
                                    </div>
                                </ion-item>
                            </ion-menu-toggle>
                        </ion-list>
                    </ion-menu-toggle>
                </ion-list>
            </ion-content>
        </ion-menu>
        <!-- Main app content get's rendered in this router-outlet -->
        <ion-router-outlet id="menu-content"></ion-router-outlet>
    </ion-split-pane>
</ion-app>