import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProvidersService } from '../providers/providers.service'

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: [
    './styles/tabs.page.scss'
  ]
})

export class TabsPage {
  translations
  constructor(
    public menu: MenuController,
    public translate: TranslateService,
    public providers: ProvidersService,
    public router: Router) {
  }

  ionViewWillEnter() {
    this.menu.enable(true);
  }

  ionTabsDidChange(event) {
  }

  onTab(tabName) {
    return this.router.url.indexOf(tabName) > 0
  }
}
