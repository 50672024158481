/// <reference types="chrome"/>
import { Injectable } from '@angular/core'
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx'
import { ProviderModel, RemoteAction } from './provider.model'
import { ProfileService } from '../profile/profile.service'
import { environment } from '../../environments/environment'
import { CompareFieldsModel } from '../compare/compare.model'
import { ProfileModel } from '../profile/profile.model'
import { Utils } from '../components/utils'
import { SettingsService } from '../settings/settings.service'

@Injectable()
export class ProviderService extends ProviderModel {
  itabConf: InAppBrowserOptions
  compareFields?: CompareFieldsModel
  compareFieldsBackup?: CompareFieldsModel
  //Use providers constants to show if value could be multiple
  multipleValues?: Array<string>
  settings: SettingsService
  baseHref: string
  public iab: InAppBrowser
  public remoteActions: RemoteAction[]
  public firstRemoteAction: string
  constructor(
    public profileService: ProfileService
  ) {
    super()
    this.itabConf = { hidden: 'no', location: 'yes', clearcache: 'no', toolbar: 'yes' }
    this.remoteActions = []
    this.multipleValues = []
    this.baseHref = this.profileService.settings.baseHref
    this.settings = this.profileService.currentUser.settings
    this.sessionExpiredDays = 0 //Default value for session expired
  }

  //Default actions
  //Check auth status - hidden
  public _logged = () => {
    let result = this.profileService.browserExtention.extentionData
    console.log('Result of logged status: ', result)
    if (result['login']) {
      console.log('User logged then get data', this.remoteActions, this.firstRemoteAction, this)
      this.getFromWeb(this.remoteActions, this.firstRemoteAction)
    } else {
      this.profileService.dlg.showTransConfirmation(['EXT.PLEASE_LOGIN_TITLE', 'EXT.PLEASE_LOGIN_INFO'], { provider: this.name },
        () => { //Cancel
          this.profileService.dlg.noticeUser('PROVIDERS.ACTION_CANCELED')
          this._loading = false
        },
        () => {//Login
          this.initLoginFromWeb()
        })
    }
  }
  //Login shown
  public _login = () => {
    console.log('_login')
    let result = this.profileService.browserExtention.extentionData
    console.log(result)
    if (result['result'] === 600) { //if user cancel action in browser
      this.profileService.dlg.noticeUser('PROVIDERS.ACTION_CANCELED')
      this._loading = false
      return
    }
    this.lastLogin = Utils.makeDate()
    if (result['login']) {
      this.auth = {
        token: '-',
        created: Utils.makeDate().toISOString(),
        tokenall: {}
      }
      this.getFromWeb([{
        id: this.id + this.loginConf.next.action,
        url: this.loginConf.next.url,
        data: '',
        hide: !this.settings.devmode
      }], this.id + this.loginConf.next.action)
    }
  }

  /**
   * Get provider's data
   * @returns provider's data as ProfileModel
   */
  getProviderData(): ProfileModel {
    if (this.userPicTmpl)
      this.userPic = this.userPicTmpl().toString()
    else this.userPic = this.user?.photoURL
    this.changed = Utils.makeDate().toISOString()
    return {
      isShell: true,
      [this.id]: {
        id: this.id,
        name: this.name,
        changed: this.changed,
        site: this.site,
        ...(this.userPic) && {
          userPic: this.userPic
        },
        archive: this.archive || false,
        ...(this.user) && {
          user: {
            ...(this.user)
          }
        },
        ...(this.auth) && {
          auth: {
            ...(this.auth)
          }
        },
        ...(this.data) && {
          data: {
            ...(this.data)
          }
        }
      }
    }
  }

  /**
   * General method to store providers data to server
   * @param then - callback function preferably to notice user of the results
   */
  store(then = () => { }) {
    this.profileService.progress.upload = true
    this.profileService.updateProfile(this.getProviderData(), this.profileService.currentUser.userid)
  }

  /**
   * General method to link provider
   * @param token - token if required
   * @param then - callback function. User to notice user with the results
   */
  getToken(token: string, then) {
    this.profileService.dlg.noticeUser('PROVIDERS.NOT_SUPPORTED_YET')
  }
  getData() {
    this.profileService.dlg.noticeUser('PROVIDERS.NOT_SUPPORTED_YET')
  }

  saveToken(token: string, tokenall) {
    this.auth = {
      token,
      tokenall,
      created: Utils.makeDate().toISOString()
    }
  }

  extentionSetAction(remoteActions: RemoteAction[], hostAction) {
    return this.profileService.browserExtention.sendMessageToExtention({ init: { remoteActions, hostAction } })
  }
  //Unfortunately Cordova execScript in application couldn't be run recursively. The issue that .then() dont fire second time. So we make the stupid timeout issue
  waitActionFinish = false
  executionChain

  applicationRequest(remoteActions: RemoteAction[], hostAction: string) {
    this.profileService.browserExtention.actionInProgress = true
    console.log('Begin actions', remoteActions, hostAction)
    this.executionChain = () => { }
    var actionPointer = remoteActions.length
    this.profileService.browserExtention.extentionData = {}
    remoteActions.reverse().forEach(action => {
      this.executionChain = () => {
        action = remoteActions[actionPointer - 1]
        this.waitActionFinish = false
        this.itabConf.hidden = this.settings.devmode ? 'no' : 'yes'
        this.itabConf.clearcache = 'no' // TODO. Apply on production later this.auth ? 'no' : 'yes'
        console.log('preBrowser start', this.itabConf)
        this['browser'] = this.iab.create(action.url, '_blank', this.itabConf);
        this.execScript(this['browser'], action)
        //this['browser'].show()
        this.waitForActionFinish(() => {
          action = remoteActions[actionPointer - 1]
          console.log('Received result message from Browser script for:', action, hostAction, actionPointer, this.data)
          this['browser'].hide()
          if (this.profileService.browserExtention.extentionData['result'] == 600) {
            this.profileService.dlg.noticeUser('PROVIDERS.ACTION_CANCELED')
            this._loading = false
            this.profileService.browserExtention.actionInProgress = false
            return //cancel all procedure
          }
          if (actionPointer > 1) {
            actionPointer--
            this.profileService.browserExtention.actionInProgress = true
            this.executionChain()
          } else {
            console.log('Finalize actions', hostAction)
            this.profileService.browserExtention.hostActions[hostAction]()
            this.profileService.dlg.noticeUser('PROVIDERS.DATA_RECEIVED', { provider: this.name })
          }
        }
        )
      }
    })
    console.log('executionChain', this.executionChain)
    this.executionChain()
  }

  waitFor = async (condFunc: () => boolean) => {
    return new Promise(resolve => {
      if (condFunc()) {
        resolve(true);
      } else {
        setTimeout(async () => {
          await this.waitFor(condFunc);
          resolve(true);
        }, 400);
      }
    });
  };

  waitForActionFinish = async (callback) => {
    await this.waitFor(() => this.waitActionFinish === true);
    this.waitActionFinish = false
    callback()
  };

  execScript(browser, action) {

    browser.on('message').subscribe(event => {
      console.log('message received', event)
      if (!event) return
      if (event.data?.loadingProgress && event.data?.provider) {
        this.profileService.browserExtention.loadingProgress.next([{ [event.data.provider]: event.data.loadingProgress }])
        //this.profileService.browserExtention.actionInProgress[event.data.provider] = event.data.loadingProgress
      }
      let result = event.data.data?.result
      console.log('event', event.data.data)
      if (result == 1024 || result == 1025) {
        console.log('action', action)
        if (!action.hide || result == 1025)
          this['browser'].show()
        return
      }
      //TODO Check error, ready to show, data ready, technical page reload - no action, progress
      if (event.data.data && !event.data.data.hasOwnProperty('loadingProgress')) {
        this.profileService.browserExtention.extentionData = Object.assign(this.profileService.browserExtention.extentionData, event.data.data)
        this.waitActionFinish = true
      }
    })

    browser.on('exit').subscribe(event => {
      console.log('Browser Exit fired')
      this.profileService.browserExtention.actionInProgress = false
      this._loading = false
      this.profileService.dlg.noticeUser('PROVIDERS.ACTION_CANCELED')
    })
    browser.on('loadstop').subscribe(event => {
      console.log('Browser script init started for action', event, action.id, action.data, this.settings.language)
      //TODO replace rnd to version number
      // if auth required
      if (event.url.indexOf('accounts.google.com/Login') > 0)
        this['browser'].show()
      this.profileService.browserExtention.getInjectJS(this.baseHref + 'assets/profile/inject.js?rnd=' + Math.random()
        .toString(36).slice(-5)).subscribe({
          next: result => {
            let code = 'window.inapp = true; var curLng = "' + this.settings.language + '";var startActionID = "' + action.id + '";var startActionData = "' + action.data + '"\n' + result //was newData
            browser.executeScript({
              code: code
            }).then(remoteResult => {
              //TODO Check if script is started or fire error
            })
              .catch(error => console.log(error))
              .finally(() => console.log('Browser script init finished'))

          },
          error: error => {
            console.log('err', error)
          }
        })
    })
  }

  /**
   * 
   * @param remoteActions  Array of actions
   * @param hostAction Host action key. Declared in appropriate provider service and stored in this.profileService.browserExtention.hostActions
   * @returns void
   * @usageNotes 
   *  this.remoteActions = [{ id:'rgGetData', url:'https://researchgate.net', data:'' },{ id: 'rgPutData', url:'https://researchgate.net', data: ''}]
      this.getFromWeb(this.remoteActions, 'rgGetData')
   */
  getFromWeb(remoteActions: RemoteAction[], hostAction) {
    if (this.profileService.platform.isNative) {
      //Android model in Webview
      this._loading = true
      this.profileService.browserExtention.actionInProgress = true
      this.applicationRequest(remoteActions, hostAction)
    } else {
      console.log('this.profileService', this.profileService)
      if (!this.profileService.browserExtention.extentionHere) {
        if (this.profileService.browserExtention.extentionVer) {
          this.profileService.dlg.showTransAlert(
            ['PROFILE.MSG_ATTENTION', 'PROFILE.MSG_EXTENSION_VERSION_MISMATCH'],
            { version: this.profileService.browserExtention.extentionVer, MinVer: environment.extention.MinVer }
          )
          return
        } else {
          this.profileService.dlg.showTransAlert(
            ['PROFILE.MSG_ATTENTION', 'PROFILE.MSG_EXTENSION_REQUIRED'],
            { provider: this.name }
          )
          return
        }
      }
      this.profileService.dlg.showTransAlert(
        ['PROFILE.MSG_ATTENTION', 'PROFILE.FOLLOW_INSTRUCTIONS_PROVIDER'],
        { provider: this.name, guid: 14 },
        () => {
          //Send an remoteActions array
          if (this.extentionSetAction(remoteActions, hostAction)) {
            this._loading = true
            this.profileService.browserExtention.actionInProgress = true
            window.open(remoteActions[0].url, '_blank');
          }
        }
      )
    }
  }
  /**
   * Check (init) auth state using web interface
   */
  initCheckAuthFromWeb() {
    console.log('initCheckAuthFromWeb')
    this.getFromWeb([{
      id: this.id + 'Logged',
      url: this.loginConf.next.url,
      data: '',
      hide: true,
      login: false
    }], this.id + 'Logged')
  }

  initLoginFromWeb() {
    console.log('initLoginFromWeb')
    this.getFromWeb([{
      id: this.id + 'Login',
      url: this.loginConf.login.url,
      data: '',
      hide: false
    }], this.id + 'Login')
  }

  doArchive() {
    this.archive = true
    this.store(
      () => this.profileService.dlg.noticeUser('PROVIDERS.PROFILE_ARCHIVED_RESULT', { provider: this.name })
    )
  }

  doUnArchive() {
    this.archive = false
    this.store(
      () => this.profileService.dlg.noticeUser('PROVIDERS.PROFILE_RESTORE_RESULT', { provider: this.name })
    )
  }

  saveData() {
    this.profileService.dlg.noticeUser('PROVIDERS.PROFILE_RESTORE_RESULT', { provider: this.name })
  }

  loadCompareModel = () => {
  }

  getStatus() {
    return 5
  }

  cancelSession() {
    delete this.auth
  }

  deleteProfile() {
    delete this.auth
    delete this.changed
    this.data = {}
    this.user = { uid: null }
    this.store(
      () => this.profileService.dlg.noticeUser('PROVIDERS.PROFILE_DELETE_RESULT', { provider: this.name })
    )
  }

}