import { Injectable } from '@angular/core'
import { Observable, of, Subscription, throwError } from 'rxjs';
import { StorageService } from '../storage/storage.service'
import { SettingsModel } from './settings.model'
import { LocationStrategy } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class SettingsService extends SettingsModel {
    language$: Observable<string | null>
    constructor(
        public storage: StorageService,
        private locationStrategy: LocationStrategy
    ) {
        super()
        this.baseHref = this.locationStrategy.getBaseHref()
        //Defaults
        this.similarity = 0.7
        this.devmode = false
        this.userId = ''
        this.alteregoOn = false
        this.alteregoId = ''
        this.adminId = 'H99NnYFu8CdTydkSrgqNRr5cpQW2'
        this.email = ''
        this.language = null
        this.itabConf = { hidden: 'no', location: 'yes', clearcache: 'no', toolbar: 'yes' }
        this.language$ = new Observable(observer => {
            observer.next(this.language)
        })
        this.params = {
            notices: {}
        }
    }

    //Load user settings
    load(userId): void {
        this.storage.load('-').then((profile) => {
            //TODO Load all settings
            if (profile && profile["language"])
                this.language = profile["language"]
                this.params = profile["notices"]
        }).catch(() => console.log('Profile load error'))
    }

    //store user settings
    setParam(param){
        this.params = Object.assign(this.params, param)
        this.storage.set(this.params,'-')
    }
    
    /**
     * Method is use to download file.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
    downLoadFile(data: any, type: string) {
        let filename = 'rwProfile.json'
        let blob = new Blob([data], { type: type });
        let url = window.URL.createObjectURL(blob);
        let downloadLink = document.createElement('a')
        downloadLink.href = window.URL.createObjectURL(blob);
        if (filename)
            downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }
    //TODO move here language settings
}