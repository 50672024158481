<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
            <ion-back-button></ion-back-button>
            <ion-title><img src="assets/icon/icon-32x85.png" style="margin-right: 10px;" title="Rewrite Today">{{"PROFILE.SOURCES_TITLE" | translate}}
            </ion-title>
        </ion-buttons>
        <ion-buttons slot="start" *ngFor="let err of topError | keyvalue">
            <ion-title color="danger">{{ err.key }}:
                {{err.value}}</ion-title>
        </ion-buttons>
        <ion-buttons slot="primary">
        </ion-buttons>
    </ion-toolbar>
    <ion-progress-bar type="indeterminate" *ngIf="profileService.progress.upload"></ion-progress-bar>
    <ion-progress-bar type="indeterminate" reversed="true" *ngIf="profileService.progress.download"></ion-progress-bar>
</ion-header>

<ion-content class="profile-content">
    <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-item color="secondary" class="callout-message-wrapper">
        <ion-icon name="information-circle" class="callout-icon"></ion-icon>
        <p class="ion-padding-start" [innerHTML]="'COMPARE.HELP_PROFILE' | translate: {linked: providers.linkedProovidersCount().linked, toadd: providers.linkedProovidersCount().toadd, archive: providers.linkedProovidersCount().archive}">
        </p>
        <ion-button class="ion-margin-vertical" [disabled]="providers.linkedProovidersCount().linked < 2" (click)="nextStep()">{{ 'NEXT' | translate}}</ion-button>
    </ion-item>
    <ion-grid ion-grid-columns="5">
        <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="4" *ngFor="let provider of providersList(false)">
                <ion-card class="md-card-bg" [id]="provider.id" #{{provider.id}}>
                    <img [src]="provider.topPic">
                    <ion-card-header class="ion-no-padding">
                        <ion-toolbar>
                            <ion-card-subtitle class="ion-padding-start">{{provider.name}}</ion-card-subtitle>
                            <ion-buttons slot="end">
                                <ion-button class="ion-no-margin" [title]="'PROFILE.UNLINK_FROM' | translate" size="small" color="primary" (click)="doUnlink(provider.id)" *ngIf="provider.hasOwnProperty('auth')">
                                    <ion-icon [title]="'PROFILE.UNLINK_FROM' | translate" name="unlink-outline"></ion-icon>
                                </ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                        <ion-card-title>
                            <ion-grid>
                                <ion-row>
                                    <ion-col *ngIf="provider.hasOwnProperty('userPic') && provider?.userPic !== undefined && provider?.userPic !== null && provider?.userPic.length > 3">
                                        <ion-avatar *ngIf="provider.changed">
                                            <app-image-shell class="user-image" animation="spinner" [src]="provider.userPic"></app-image-shell>
                                        </ion-avatar>
                                    </ion-col>
                                    <ion-col class="ion-align-items-center" *ngIf="provider.changed && provider.hasOwnProperty('data') && provider.data?.changed">
                                        <app-text-shell [data]="provider?.user?.displayName"></app-text-shell>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        <ion-grid>
                            <ion-row>
                                <ion-col>
                                    <p *ngIf="provider?.user?.uid">
                                        <ion-icon name="barcode-outline"></ion-icon>
                                        <b>
                                        <span class="link" (click)="openExternalPage(provider.user?.profileUrl)" title="Open in new window">
                                            {{ provider.user?.uid }}
                                        </span></b>
                                    </p>
                                    <p *ngIf="provider?.changed && provider.hasOwnProperty('data') && provider.data?.changed" title="Updated">
                                        <ion-icon name="hourglass-outline"></ion-icon>
                                        {{ provider.changed | date:'fullDate' }}
                                    </p>
                                </ion-col>
                            </ion-row>
                            <ion-row>
                                
                                <ion-button [attr.disabled]="provider['_loading'] || profileService.browserExtention.actionInProgress" [title]="'PROFILE.LINK_TO' | translate" size="small" color="secondary" (click)="doLink(provider.id)" *ngIf="!provider.hasOwnProperty('auth')">
                                    <ion-icon name="link-outline"></ion-icon>
                                    <ion-icon slot="start" src="./assets/custom-icons/loading.svg" *ngIf="provider['_loading']"></ion-icon>
                                    <ion-label translate>PROFILE.LINK_TO</ion-label>
                                </ion-button>
                                <ion-button [title]="'PROVIDERS.PROFILE_ARCHIVE' | translate" size="small" color="primary" (click)="doArchive(provider.id)" *ngIf="!provider.hasOwnProperty('auth')">
                                    <ion-icon name="link-outline"></ion-icon>
                                    <ion-label translate>PROVIDERS.PROFILE_ARCHIVE</ion-label>
                                </ion-button>
                                <ion-button [attr.disabled]="provider['_loading'] ||profileService.browserExtention.actionInProgress" [title]="'PROFILE.GET_DATA' | translate" size="small" color="tertiary" (click)="getData(provider.id)" *ngIf="provider.hasOwnProperty('auth')">
                                    <ion-icon name="person-outline"></ion-icon>
                                    <ion-icon slot="start" src="./assets/custom-icons/loading.svg" *ngIf="provider['_loading']"></ion-icon>
                                    <ion-label slot="start" *ngIf="(provider['_loading'] || profileService.browserExtention.actionInProgress) && loadingProgress[provider.id]">{{ loadingProgress[provider.id] }} %</ion-label>
                                    <ion-label translate>PROFILE.GET_DATA</ion-label>
                                </ion-button>
                                <ion-button [attr.disabled]="provider['_loading']" [title]="'PROFILE.COMPARE' | translate" size="small" color="secondary" (click)="goCompare(provider.id)" *ngIf="provider.hasOwnProperty('data') && provider.hasOwnProperty('auth') && provider.changed && (providers.linkedProovidersCount().linked > 1)">
                                    <ion-icon name="git-compare-outline"></ion-icon>
                                    <ion-label translate>PROFILE.COMPARE</ion-label>
                                </ion-button>
                            </ion-row>
                        </ion-grid>
                    </ion-card-content>
                </ion-card>
            </ion-col>
        </ion-row>
    </ion-grid>
    <h1 *ngIf="providersList(true).length > 0">Archive</h1>
    <ion-grid>
        <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="4" *ngFor="let provider of providersList(true)">
                <ion-card class="md-card-bg">
                    <img [src]="provider.topPic">
                    <ion-card-header class="ion-no-padding">
                        <ion-toolbar>
                            <ion-card-subtitle class="ion-padding-start">{{provider.name}}</ion-card-subtitle>
                            <ion-buttons slot="end">
                                <ion-button [title]="'PROVIDERS.PROFILE_RESTORE' | translate" size="small" color="primary" (click)="doRestore(provider.id)">
                                    <ion-icon name="link-outline"></ion-icon>
                                    <ion-label translate>PROVIDERS.PROFILE_RESTORE</ion-label>
                                </ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-card-header>
                </ion-card>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
