import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { Routes, RouterModule } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { redirectUnauthorizedTo } from '@angular/fire/auth-guard'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { environment } from '../../environments/environment'

import { ProvidersModule } from '../providers/providers.module'
import { ComponentsModule } from '../components/components.module'
import { ProfilePage } from './profile.page'

//Language
import { TranslateModule } from '@ngx-translate/core'

const routes: Routes = [
  {
    path: '',
    component: ProfilePage
  }
];

@NgModule({
  imports: [
    CommonModule,
    ProvidersModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    ComponentsModule,
    TranslateModule//Language
  ],
  declarations: [
    ProfilePage
  ],
  providers: [
    InAppBrowser
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProfilePageModule { }
