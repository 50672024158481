import { Component, HostListener } from '@angular/core';
import { HistoryHelperService } from './utils/history-helper.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { StorageService } from './storage/storage.service';

import { environment } from '../environments/environment'
import { ProvidersService } from './providers/providers.service'
import { ProfileService, CurrentUser } from './profile/profile.service'
import { Subscription, Observable } from 'rxjs';
import { ProfileModel, MyProfilesListModel } from './profile/profile.model';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from './settings/settings.service'
import { LanguageService } from './language/language.service'
import { Utils } from './components/utils'
import { ProviderModel } from './providers/provider.model'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './side-menu/styles/side-menu.scss',
    './side-menu/styles/side-menu.shell.scss',
    './side-menu/styles/side-menu.responsive.scss'
  ]
})
export class AppComponent {
  subscriptions: Subscription
  userProfile: ProfileModel
  profileDataSource: Observable<ProfileModel>
  params: {
    provider: string,
    token: string,
  }
  topError = {}
  saveTokenBusy = false
  blurWindow = false
  appPages = [
    {
      id: 0,
      title: 'PROFILE.SOURCES_TITLE',
      url: '/profile',
      ionicIcon: 'color-wand'
      //customIcon: environment.root + 'assets/custom-icons/side-menu/contact-card.svg'
    }, {
      id: 1,
      title: 'COMPARE.PROFILE_GENERAL',
      url: '/tabs/compare/current',
      ionicIcon: 'body'
    }, {
      id: 2,
      title: 'COMPARE.PROFILE_PUBLICATIONS',
      url: '/analyse',
      ionicIcon: 'book'
    }, {
      id: 3,
      title: 'COMPARE.PROFILE_EMPLOYMENT',
      url: '/tabs/employment/current',
      ionicIcon: 'business'
    }, /* {
      id: 4,
      title: 'Contacts',
      url: '/profile',
      ionicIcon: 'people'
    }, */
    {
      id: 5,
      title: 'SETTINGS.SETTINGS_TITLE',
      url: '/settings',
      ionicIcon: 'settings'
    }
  ];

  @HostListener('window:blur', ['$event'])
  onBlur(event: FocusEvent): void {
    this.blurWindow = true
  }
  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    this.blurWindow = false
  }



  // Inject HistoryHelperService in the app.components.ts so its available app-wide
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public historyHelper: HistoryHelperService,
    public providers: ProvidersService,
    public profileService: ProfileService,
    public currentUser: CurrentUser,
    private route: ActivatedRoute,
    public router: Router,
    private storage: StorageService,
    public settings: SettingsService,
    public language: LanguageService
  ) {
    this.platform.ready().then(() => {
      this.language.initLang().then(() => {
        let browserType = Utils.get_browser()
        this.profileService.platform = {
          isDevel: document.URL.startsWith('http://localhost:810'),
          isNative: this.platform.is('cordova'),
          incompatible: browserType.name != 'Chrome' && browserType.name != 'Opera' && !this.platform.is('cordova'),
          container: browserType.name
        }
        if (!this.profileService.platform.incompatible || this.platform.is('cordova')) {
          this.statusBar.styleDefault();
          this.init()
        } else
          this.router.navigate(["/unsupported"]).catch((rez) => console.log('Navigate error', rez));
      })
    })
      .catch((rez) => console.log('Platform Ready error', rez));
  }

  init(): void {
    //Load local profiles list
    //If exists then propose to select then login
    //If user is logged then load local data. Otherwise request password.
    //If not exists then verify phone then. Request profile name and create profile.
    //Login by QR code using data on other device
    this.currentUser.getProfileList()
      .then(myProfiles => {
        if (myProfiles && myProfiles.selected) {
          //profile is selected
          this.currentUser.userid = myProfiles.selected.profileId
        } else if (myProfiles && myProfiles.list) {
          //profile is not selected
          //Show select profile dialogue
        } else {
          //No profiles
          const profileId = Utils.randomKey()
          let newMyProfileItem: MyProfilesListModel = {
            list: [{
              profileId: profileId
            }],
            selected: {
              profileId: profileId
            }
          }
          this.currentUser.userid = profileId
          this.currentUser.setProfileList(newMyProfileItem)
          this.profileService.createProfileData()
        }
        this.settings.userId = this.currentUser.userid
        this.route.queryParams.subscribe((params) => {
          if (params?.provider && params?.token && !this.saveTokenBusy) {
            this.saveTokenBusy = true
            this.saveToken(params?.provider, params?.token, params)
          }
        });
        console.log('Got user', this.currentUser.userid)
        this.profileService.getProfileData(this.currentUser.userid).then((profile) => {
          this.providers.load(profile)
          //this.router.navigate(["/profile"]).catch((rez) => console.log('Navigate error', rez));
        })
      })
      .catch(e => console.log(e))
    if (!this.profileService.platform.isNative)
      this.profileService.browserExtention.initExtention()
    this.storage.getData().subscribe(profile => {
      if (profile)
        this.providers.load(profile)
      if (profile['params'])
        this.settings.params = profile['params']
    })
  }

  saveToken(provider: string, token: string, tokenall: Record<string, string>): void {
    this.providers.item[provider].saveToken(token, tokenall)
    this.providers.item[provider].store(() => {
      this.saveTokenBusy = false
      this.router.navigateByUrl("/profile")
    })
  }
  getIndexes(): ProviderModel[] {
    return this.providers.list.filter(provider => provider.compareFields?.indexes)
  }
  pubsCount(provider: ProviderModel): number {
    return provider?.compareFields?.publications ? Object.keys(provider.compareFields.publications).length : 0
  }
}
