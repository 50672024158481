import { Injectable } from '@angular/core'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'

import { ProviderService } from '../provider.service'
import { ProfileService } from '../../profile/profile.service'
import { ScholarModel, ScholarPublications } from './scholar.model'
import { ComparePublicationIDs } from '../../compare/compare.model'
import { RemoteAction } from '../provider.model'
import { Utils } from '../../components/utils'

@Injectable()
export class ScholarService extends ProviderService {
  id = 'scholar'
  name = "Google Scholar"
  topPic = `${this.baseHref}assets/providers/${this.id}/topPic.png`
  customIcon = `${this.baseHref}assets/providers/${this.id}/icone.svg`
  site = "scholar.google.com"
  loginConf = {
    login: {
      url: 'https://accounts.google.com/Login?hl=ru&continue=https://scholar.google.com'
    },
    next: {
      url: 'https://scholar.google.com',
      action: 'GetData'
    }
  }
  userPic = ''
  data: ScholarModel

  constructor(
    public profileService: ProfileService,
    public iab: InAppBrowser
  ) {
    super(
      profileService
    )
    if (!this.data)
      this.data = new ScholarModel
    this.profileService.browserExtention.hostActions[this.id + 'Logged'] = this._logged
    this.profileService.browserExtention.hostActions[this.id + 'Login'] = this._login

    //Get data - hidden
    this.profileService.browserExtention.hostActions[this.id + 'GetData'] = () => {
      let result = this.profileService.browserExtention.extentionData
      console.log(result)
      if (Object.keys(result).length < 1) return
      if (!result['login']) {
        //Confirmation (Login|cancel)
        this.profileService.dlg.showTransConfirmation(['EXT.PLEASE_LOGIN_TITLE', 'EXT.PLEASE_LOGIN_INFO'], { provider: this.name },
          () => {  //Cancel
            this.profileService.dlg.noticeUser('PROVIDERS.ACTION_CANCELED')
            this._loading = false
            this.profileService.browserExtention.actionInProgress = false
          },
          () => {
            this.initLoginFromWeb()
          })
        this.cancelSession()
        return
      }
      //Load data (Update || keep || default)
      //General section
      this.user = {
        uid: result['userid'] || this.user.uid || '',
        displayName: result['displayName'] || this.user.displayName || '',
        photoURL: result['avatar'] || this.user.photoURL || '',
        profileUrl: result['profileurl'] || this.user.profileUrl || ''
      }
      if (result['userid'])
        this.auth = {
          token: result['userid'] || '',
          created: Utils.makeDate().toISOString(),
          tokenall: {}
        }
      //publications section
      /**
       * Copy old values
       * Find new record in old list and copy to new object
       * If none create new object and add to the new object
       * replace old data with new object (by properties)
       * TODO mark new and save deleted data
       */
      this.data['publications'] ??= {}
      let newPubData: { [pubid: string]: ScholarPublications } = {}
      let oldPubData: { [pubid: string]: ScholarPublications } = Object.assign({}, this.data.publications)
      //if it has old data: Store key: ids
      if (result['publications']) {
        let new_IDs: ComparePublicationIDs
        if (Object.entries(result['publications']))
          Object.entries(result['publications']).forEach(([id, newPublicationData]) => {
            let curID = Utils.randomKey()
            new_IDs = {
              gspid: this.hashCode(newPublicationData['title'])
            }
            Object.entries(this.data.publications).forEach(([oldPublicationID, oldPublicationData]) => {
              if (oldPublicationData?.ids && oldPublicationData?.ids?.gspid == new_IDs.gspid)
                curID = oldPublicationID
            })
            if (oldPubData[curID])
              new_IDs = { ...oldPubData[curID].ids, ...new_IDs }
            newPubData[curID] = {
              type: newPublicationData['articleType'] || 0,
              title: this.prefilterTitle(newPublicationData['title']) || '',
              publication_date: newPublicationData['pubdate'] || '',
              pub_date_year: newPublicationData['pubdate'].split('/')[0] || '',
              pub_date_month: newPublicationData['pubdate'].split('/')[1] || '',
              pub_date_day: newPublicationData['pubdate'].split('/')[2] || '',
              pubvenue: newPublicationData['pubvenue'] || '',
              volume: newPublicationData['volume'] || '',
              issue: newPublicationData['issue'] || '',
              pages: newPublicationData['pages'] || '',
              publisher: newPublicationData['publisher'] || '',
              patoffice: newPublicationData['patoffice'] || '',
              patnumber: newPublicationData['patnumber'] || '',
              patapplic: newPublicationData['patapplic'] || '',
              curt: newPublicationData['curt'] || '',
              reporter: newPublicationData['reporter0'] || '',
              docketid: newPublicationData['docketid'] || '',
              authors: (newPublicationData['authors'] || '').split(',').map(value => {
                return { fullName: value }
              }) || '',
              ids: { ...new_IDs }
            }
          })
      } else { //Keep olda data if none incoming
        newPubData = oldPubData
      }
      //prefilter data
      this.data = {
        indexes: result['indexes'] || '',
        affiliation: result['affiliation'] || '',
        keywords: result['keywords'] || '',
        email: result['email'] || '',
        web: result['web'] || '',
        visibility: result['visibility'] || 1,
        publications: { ...newPubData } || {}
      }
      this.data.changed = Utils.makeDate().toISOString()
      this.store()
      this._loading = false
      this.profileService.browserExtention.actionInProgress = false
    }

    //Get result of save data procedure
    this.profileService.browserExtention.hostActions[this.id + 'SaveDataFin'] = () => {
      //Delete backup keys
      delete this.compareFieldsBackup
      //Copy data from compare Model to Provider data
      this.user.displayName = this.compareFields.displayName
      this.data.keywords = this.compareFields.keywords.map(val => { return val.content }).join(", ")
      this.data.affiliation = this.compareFields.affiliation[0].org_title
      this.store()
      this._loading = false
      this.profileService.browserExtention.actionInProgress = false
      this.profileService.dlg.noticeUser('PROVIDERS.DATA_SAVED')
      //
    }
  }

  //TODO Hidden visible browser variations
  prefilterTitle(title) {
    const treg = / {sub ([^}]*)}/g;
    return title.replace(treg, '$1');
  }

  getToken(token: string, then) {
    this.remoteActions = [{
      id: this.id + 'GetData',
      url: 'https://scholar.google.com',
      data: '',
      hide: !this.settings.devmode
    }]
    this.firstRemoteAction = this.id + 'GetData'
    console.log('getToken', this.loginConf.next.action, this.profileService.browserExtention.hostActions)
    this.initLoginFromWeb()
  }

  getData() {
    this.remoteActions = [{
      id: this.id + 'GetData',
      url: 'https://scholar.google.com',
      data: '',
      hide: !this.settings.devmode
    }]
    this.firstRemoteAction = this.id + 'GetData'
    //Check auth state
    if (Utils.dateDiffInDays(this.lastLogin, Utils.makeDate()) < this.sessionExpiredDays)
      this.getFromWeb(this.remoteActions, this.firstRemoteAction)
    else
      //Session expired
      this.initCheckAuthFromWeb()
  }

  saveData() {
    console.log(this.compareFields.keywords, this.compareFields.keywords.map(val => (val)), this.compareFields.keywords.map(val => (val)).join(", "))
    this.remoteActions = [{
      id: this.id + 'SaveData',
      url: 'https://scholar.google.com',
      data: {
        displayName: this.compareFields.displayName,
        keywords: this.compareFields.keywords.map(val => { return val.content }).join(", "),
        affiliation: this.compareFields.affiliation[0].org_title,
        //Unsupported yet. Need browser form error handling
        email: this.compareFields.email
      }
    }]
    this.firstRemoteAction = this.id + 'SaveDataFin'
    //Check auth state
    if (Utils.dateDiffInDays(this.lastLogin, Utils.makeDate()) < this.sessionExpiredDays)
      this.getFromWeb(this.remoteActions, this.firstRemoteAction)
    else
      //Session expired
      this.initCheckAuthFromWeb()
  }

  loadCompareModel = () => {
    if (!this.compareFields) this.compareFields = {}
    let data = this.data
    if (data?.publications) {
      if (!this.compareFields?.publications)
        this.compareFields['publications'] = {}
    }
    this.compareFields = {
      photoURL: this.userPic,
      displayName: this.user.displayName, //TODO displayName in data field
      keywords: Object.assign([], [...(data?.keywords || '').split(',').map(val => ({ content: val.trim() })).sort((a, b) => (a.content > b.content ? 1 : -1))]),
      affiliation: [{ org_title: data.affiliation }],
      email: data.email,
      publications: data.publications,
      indexes: {
        h_all: data?.indexes?.h_all,
        h_5: data?.indexes?.h_5,
        i10_5: data?.indexes?.i10_5,
        i10_all: data?.indexes?.i10_all,
        citations_5: data?.indexes?.citations_5,
        citations_all: data?.indexes?.citations_5
      }
    }
  }
  //Utils
  hashCode = function (s) {
    return s.split("").reduce(function (a, b) { a = ((a << 5) - a) + b.charCodeAt(0); return a & a }, 0);
  }

}