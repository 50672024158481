import { Injectable, Component } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'rw-utils',
  template: ''
})
export class Utils {
  constructor() { }
  /**
   * Random string this length =5 
   * @returns Random string
   */
  public static randomKey() {
    const c = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    return [...Array(5)].map(_ => c[~~(Math.random() * c.length)]).join('')
  }

  /**
   * Compare versions util
   * @param a version
   * @param b version
   * @returns 0 - eual, 1 - a > b, -1 a < b
   */
  public static cmpVersions = (a, b) => {
    var i, cmp, len;
    a = (a + '').split('.');
    b = (b + '').split('.');
    len = Math.max(a.length, b.length);
    for (i = 0; i < len; i++) {
      if (a[i] === undefined) {
        a[i] = '0';
      }
      if (b[i] === undefined) {
        b[i] = '0';
      }
      cmp = parseInt(a[i], 10) - parseInt(b[i], 10);
      if (cmp !== 0) {
        return (cmp < 0 ? -1 : 1);
      }
    }
    return 0;
  }

  /**
   * Copy to clipboard function
   * @param val - value to copy to clipboard
   */
  public static copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  /**
   * Get object property by path string
   * @param obj 
   * @param parse 
   */
  public static parseObjectProperties(obj, parse) {
    for (var k in obj) {
      if (typeof obj[k] === 'object' && obj[k] !== null) {
        this.parseObjectProperties(obj[k], parse)
      } else if (obj.hasOwnProperty(k)) {
        parse(k, obj[k])
      }
    }
  }

  public static makeDate(shift: number = 0) {
    return new Date((new Date()).getTime() + shift);
  }

  public static parseISOString(s) {
    var b = s.split(/\D+/);
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
  }

  // a and b are javascript Date objects
  public static dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  public static get_browser() {
    var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR\/(\d+)/)
      if (tem != null) { return { name: 'Opera', version: tem[1] }; }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
    return {
      name: M[0],
      version: M[1]
    };
  }

  /**
   * Get object property protected from undefined ancestors
   * @param data - object
   * @param path - array of properties name
   * @returns value of property 
   */
  public static getValueSafer = function (data, path) {
    var i, len = path.length
    for (i = 0; typeof data === 'object' && i < len; ++i) {
        data = data[path[i]]
    }
    return data
}

}