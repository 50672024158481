import { ComparePublications } from "../compare/compare.model"
export class GroupModel {
    ofPublications?: {
        list: PublicationsGroups,
        keys: Array<string>,
        provider: {
            [providerId: string]: {
                current: {
                    [groupId: string]: {
                        ids?: {
                            value: string
                            type: string
                        }[],
                        items?: {
                            provider: string
                            id: string
                        }[]
                    }
                },
                other: {
                    [groupId: string]: {
                        ids?: {
                            value: string
                            type: string
                        }[],
                        items?: {
                            provider: string
                            id: string
                        }[]
                    }
                },
                currentLength: number,
                otherLength: number
            }
        },
    }
    constructor() {
        this.ofPublications = {
            list: {},
            keys: [],
            provider: {}
        }
    }
}
//TODO add date field to do sort
export class PublicationsGroups {
    [groupId: string]: {
        ids: {
            type: string,
            value: string
        }[],
        items: {
            id: string,
            provider: string
        }[],
        correct?: ComparePublications,
        deviation?: {
            [providerId: string]: {
                rpid?: string,
                hasduplicate?: boolean,
                type?: number, //0 - correct, 1 - not filled, 2 - not correct, 3 - no full
                title?: number,
                authors?:number,
                pub_date_year?: number
                pub_date_month?:number
                pub_date_day?: number
                pubvenue?: number
                volume?: number
                issue?: number
                pages?: number
                publisher?: number
                patoffice?: number
                patapplic?: number
                patnumber?: number
                curt?: number
                reporter?: number
                docketid?: number
                abstract?: number
                keywords?: number
                cited?: number
                link?: number
            }
        }
        unsupported?: {}, //Hash of unsupported providers
        newPublications?: {} //Unsaved publications
    }
}