import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Storage, StorageConfig } from '@ionic/storage-angular';
import { ProfileModel, MyProfilesListModel } from '../profile/profile.model'

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private myData: BehaviorSubject<ProfileModel> = new BehaviorSubject<ProfileModel>({isShell: false});
    private _storage: Storage | null = null;

    constructor(private storage: Storage) {
    }

    async init() {
        // If using, define drivers here: await this.storage.defineDriver(/*...*/);
        const storage = await this.storage.create();
        this._storage = storage;
    }

    /**
     * 
     * @returns 
     */
     async getUserProfiles(): Promise<MyProfilesListModel> {
        await this.init();
        return await this._storage.get('profiles.list')
    }

    async setUserProfiles(userData) {
        await this.init();
        this.myData.next(userData)
        return await this._storage.set('profiles.list', userData)
    }    
    
    async load(userID = ''): Promise<ProfileModel> {
        await this.init();
        const updateData: ProfileModel = { isShell: true };
        const keys = await this._storage?.keys();
        for (const key of keys) {
            const itemKeyParts = key.split('.')
            if (itemKeyParts[0] === 'profiles' && itemKeyParts[1] == userID) {
                updateData[itemKeyParts[2]] = await this._storage?.get(key)
            }
        }
        return updateData
    }

    async deleteUserData(userID) {
        await this.init();
        (await this._storage?.keys()).forEach(key => {
            let itemKeyParts = key.split('.')
            if (itemKeyParts[0] === 'profiles' && itemKeyParts[1] == userID) {
                this._storage?.remove(key)
            }
        })
        await this._storage.remove('currentUser')
    }

    async setAlterego(userID) {
        await this.init();
        return await this._storage.set('alterego', userID)
    }
    
    async deleteAlterego(userID) {
        await this.init();
        (await this._storage?.keys()).forEach(key => {
            let itemKeyParts = key.split('.')
            if (itemKeyParts[0] === 'profiles' && itemKeyParts[1] == userID) {
                this._storage?.remove(key)
            }
        })
        await this._storage.remove('alterego')
    }

    // Create and expose methods that users of this service can
    // call, for example:
    async set(data, userID) {
        if (!userID) return
        await this.init();
        let keys = Object.keys(data);
        for (let key of keys)
            await this._storage?.set(`profiles.${userID}.${key}`, data[key])
        this.myData.next(data)
    }
    /**
     * Get value from cache by key
     * @param key record key
     * @param userID user id
     * @returns record value
     * TODO move user id to upper level
     */
    async get(key, userID) {
        await this.init();
        if (!userID) return
        let fullKey = 'profiles.' + userID + '.' + key
        return await this._storage?.get(fullKey)
    }

    getData(): Observable<ProfileModel> {
        return this.myData;
    }
}