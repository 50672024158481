import { Injectable } from '@angular/core';
import { ProviderService } from '../provider.service'
import { ProfileService } from '../../profile/profile.service';
import { PublonsModel, PublonsPublication } from './publons.model'
import { RemoteAction } from '../provider.model'

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { CompareModel, CompareFieldsModel, ComparePublicationIDs } from '../../compare/compare.model'
import { Utils } from '../../components/utils'

@Injectable()
export class PublonsService extends ProviderService {
  //TODO move psToken to user settings
  psToken = 'b1a9e622b1bddfec43223a1150be86e8cb00b64d'
  id = 'publons'
  name = "Publons"
  topPic = `${this.baseHref}assets/providers/${this.id}/topPic.png`
  customIcon = `${this.baseHref}assets/providers/${this.id}/icone.svg`
  site = "publons.com"
  loginConf = {
    login: {
      url: 'https://publons.com/dashboard/summary/'
    },
    next: {
      url: 'https://publons.com/dashboard/summary/',
      action: 'GetAuthData'
    }
  }
  userPic = ''
  data: PublonsModel
  tempPhoto: string //Use if Publons have no custom photo

  /**
* Index based on Google Scholar
* 0 - journal
* 1 - book
* 3 - conference
* 4 - chapter
* 5 - book-review - ORCID
* 8 - other
* 9 - thesis
* 12 - patent
* 17 - courtcase
*/

  getPubTypeIndex = {
    'article': 0,
    'book': 1,
    'inCollection': 4,  //Use RG key
    'chapter': 4,       //Use RG value
    'code': 8,
    'inProceedings': 3,
    'conference-paper': 3,  //use RG value. Lowercase, replace space to dash
    'coverPage': 8,         //key
    'cover-page': 8,        //value. Lowercase, replace space to dash
    'dataset': 8,           //key
    'data': 8,              //value
    'experimentFindings': 8,  //key
    'experiment-findings': 8, //value
    'method': 8,
    'negativeResults': 8,     //key
    'negative-results': 8,    //value
    'patent': 12,
    'poster': 8,
    'preprint': 0,
    'presentation': 8,
    'rawData': 8,             //key
    'raw-data': 8,            //value
    'researchProposal': 8,
    'research-proposal': 8,
    'technicalReport': 3,
    'technical-report': 3,
    'thesis': 9
  }

  constructor(
    public profileService: ProfileService,
    public iab: InAppBrowser
  ) {
    super(
      profileService
    )
    this.data = new PublonsModel
    this.profileService.browserExtention.hostActions[this.id + 'Logged'] = this._logged
    this.profileService.browserExtention.hostActions[this.id + 'Login'] = this._login

    this.profileService.browserExtention.hostActions[this.id + 'GetAuthData'] = () => {
      let result = this.profileService.browserExtention.extentionData
      console.log(result)
      if (result['result'] === 600) { //if user cancel action in browser
        this.profileService.dlg.noticeUser('PROVIDERS.ACTION_CANCELED')
        this._loading = false
        this.profileService.browserExtention.actionInProgress = false
        return
      }
      if (!result['login']) {
        //Confirmation (Login|cancel)
        this.profileService.dlg.showTransConfirmation(['EXT.PLEASE_LOGIN_TITLE', 'EXT.PLEASE_LOGIN_INFO'], { provider: this.name },
          () => { //Cancel
            this.profileService.dlg.noticeUser('PROVIDERS.ACTION_CANCELED')
            this._loading = false
            this.profileService.browserExtention.actionInProgress = false
          }, () => {
            this.initLoginFromWeb()
          })
        this.cancelSession()
        return
      } else {
        this.user = {
          uid: result['rid'] || '',
          photoURL: result['avatar'] || '',
          profileUrl: result['profileurl'] || ''
        }
        this.tempPhoto = result['avatar'] || ''
        this.auth = {
          id: result['rid'] || '',
          token: result['userid'] || '',
          tokenall: {},
          created: Utils.makeDate().toISOString()
        }
        //Get data using API
        let uid = result['rid']
        this.profileService.browser.getData('https://publons.com/api/stats/individual/' + uid, this.psToken)
          .then(result => {
            this.data.indexes = {
              hIndex: result.hIndex,
              timesCited: result.timesCited
            }
            this.data.changed = Utils.makeDate().toISOString()
            this.changed = Utils.makeDate().toISOString()
            this.profileService.dlg.noticeUser('PROVIDERS.DATA_RECEIVED', { provider: this.name })
            this.data.partial = true
            this.store(() => { })
            this._loading = false
            this.profileService.browserExtention.actionInProgress = false
          }).then(result1 => {
            //Get statistics
            this.profileService.browser.getData('https://publons.com/api/v2/academic/' + uid, this.psToken)
              .then(result => {
                this.data = result
                this.data.changed = Utils.makeDate().toISOString()
                this.auth = {
                  token: '',  //TODO
                  id: result.ids.id,
                  tokenall: {},
                  created: Utils.makeDate().toISOString()
                }
                this.user = {
                  uid: result.ids.id,
                  displayName: result?.publishing_name,
                  photoURL: result['photo'] || this.tempPhoto || '',
                  profileUrl: result.ids.url
                }
                this.changed = Utils.makeDate().toISOString()
                this.profileService.dlg.noticeUser('PROVIDERS.DATA_RECEIVED', { provider: this.name })
                this.data.partial = true
                this.store(() => { })
                this._loading = false
                this.profileService.browserExtention.actionInProgress = false
              })
          })
      }
    }
  }

  loadCompareModel = () => {
    let data = this.data
    let publications = {}
    if (data?.publications?.results)
      publications = Object.assign({},
        ...[
          ...data?.publications?.results.map(val => {
            let rpid = 'publons_' + val.publication.ids.id || Utils.randomKey()
            return {
              [rpid]: {
                pubvenue: val.journal?.name || '',
                title: val.publication.title,
                type: 0,
                publication_date: val.publication.date_published,
                pub_date_year: val.publication.date_published.split('-')[0],
                pub_date_month: val.publication.date_published.split('-')[1],
                pub_date_day: val.publication.date_published.split('-')[2],
                ids: {
                  rpid,
                  api: val.publication.ids.api,
                  arXiv: val.publication.ids.arXiv,
                  doi: val.publication.ids.doi,
                  pmid: val.publication.ids.pmid,
                  ut: val.publication.ids.ut,
                  url: val.publication.ids.url
                }
              }
              //To make publicationId should be persistent we use provider's pub id as RW pub id
            }
          })

        ]
      )
    this.compareFields = {
      photoURL: data['photo'],
      displayName: data['publishing_name'],
      publishing_names: data?.publishing_names ? data?.publishing_names.map(name => { return { content: name } }) : [],
      publications,
      indexes: {
        h_all: data?.indexes?.hIndex,
        citations_all: data?.indexes?.timesCited
      }
    }
  }

  getToken(token: string, then) {
    this.remoteActions = [{
      id: 'psLogin',
      url: 'https://publons.com/dashboard/summary/',
      data: '',
      hide: true
    }]
    this.firstRemoteAction = this.id + 'GetAuthData'
    this.initLoginFromWeb()
  }

  getData() {
    this.profileService.browserExtention.actionInProgress = true
    let uid = this.data.ids.id ? this.data.ids.id : this.profileService.userIds['orcid']
    this.profileService.browser.getData('https://publons.com/api/v2/academic/' + uid, this.psToken)
      .then(result => {
        this.data = result
        this.data.changed = Utils.makeDate().toISOString()
        this.auth = {
          token: '',  //TODO
          id: result.ids.id,
          tokenall: {},
          created: Utils.makeDate().toISOString()
        }
        this.user = {
          uid: result.ids.id,
          displayName: result?.publishing_name,
          photoURL: result['photo'] || this.tempPhoto || '',
          profileUrl: result.ids.url
        }
        //Get sub requests
        //Publications
        console.log('getPubs', result?.publications?.url, this.psToken)
        this.profileService.browser.getData(result?.publications?.url, this.psToken)
          .then(resultPublications => {
            console.log('resultPublications', resultPublications)
            this.data.publications = resultPublications
          })
      })
      .then(result1 => {
        this.profileService.browser.getData('https://publons.com/api/stats/individual/' + this.user.uid, this.psToken)
          .then(result => {
            console.log('got index data', result)
            this.data.indexes = {
              hIndex: result.hIndex,
              timesCited: result.timesCited
            }
            this.data.changed = Utils.makeDate().toISOString()
            this.changed = Utils.makeDate().toISOString()
            this.profileService.dlg.noticeUser('PROVIDERS.DATA_RECEIVED', { provider: this.name })
            this.store(() => { })
            this._loading = false
            this.profileService.browserExtention.actionInProgress = false
          })
          this.data.partial = false
      })
  }

}