import { ComparePublicationIDs } from '../../compare/compare.model'

export class ScholarModel {
  affiliation?: string
  keywords?: string
  email?: string
  web?: string
  visibility?: number = 0
  changed?: string
  indexes?: {
    citations_all: number,
    citations_5: number,
    h_all: number,
    h_5: number,
    i10_all: number,
    i10_5: number
  }
  publications?: { [pubid: string]: ScholarPublications }
}

export class ScholarPublications {
  ids?: ComparePublicationIDs
  type?: number
  title: string
  publication_date?: string
  pub_date_year?: string
  pub_date_month?: string
  pub_date_day?: string
  pubvenue?: string
  volume?: string
  issue?: string
  pages?: string
  publisher?: string
  patoffice?: string
  patnumber?: string
  patapplic?: string
  curt?: string
  reporter?: string
  docketid?: string
  link?: string
  authors: {
    id?: string
    fullName: string
  }[]
}