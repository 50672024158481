import { Injectable } from '@angular/core';
import { ProviderService } from '../provider.service'
import { ProfileService } from '../../profile/profile.service';
import { OrcidModel } from './orcid.model'

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { environment } from '../../../environments/environment';
import { CompareFieldsModel, ComparePublicationIDs } from '../../compare/compare.model'
import { Utils } from '../../components/utils'

@Injectable()
export class OrcidService extends ProviderService {
  id = 'orcid'                            //RW constant
  name = "ORCID"                          //Provider constant
  topPic = `${this.baseHref}assets/providers/${this.id}/topPic.png`
  customIcon = `${this.baseHref}assets/providers/${this.id}/icone.svg`
  site = "orcid.com"                      //Provider constant
  data: OrcidModel                        //Data model come from Provider
  multipleValues = [                      //List multipe interfaces defined by provider's data model
    "affiliation",
    "publications"
  ]
  scopes = {                              //API interface constant from provider
    auth: 'openid'
  }
  // https://info.orcid.org/faq/what-work-types-does-orcid-support/
  getPubTypeIndex = {
    'book': 1,
    'book-chapter': 4,
    'book-review': 8,
    'dictionary-entry': 8,
    'dissertation': 9,
    'dissertation-thesis': 9,
    'encyclopedia-entry': 8,
    'edited-book': 1,
    'journal-article': 0,
    'journal-issue': 0,
    'magazine-article': 0,
    'manual': 8,
    'online-resource': 8,
    'newsletter-article': 0,
    'newspaper-article': 0,
    'preprint': 0,
    'report': 8,
    'research-tool': 8,
    'supervised-student-publication': 0,
    'test': 8,
    'translation': 8,
    'website': 8,
    'working-paper': 0,
    //Conference
    'conference-abstract': 3,
    'conference-paper': 3,
    'conference-poster': 3,
    //Intellectual Property
    'disclosure': 12,
    'license': 12,
    'patent': 12,
    'registered-copyright': 12,
    'trademark': 12,
    //Other
    'annotation': 8,
    'artistic-performance': 8,
    'data-management-plan': 8,
    'data-set': 8,
    'invention': 8,
    'lecture-speech': 8,
    'physical-object': 8,
    'research-technique': 8,
    'software': 8,
    'spin-off-company': 8,
    'standards-and-policy': 8,
    'technical-standard': 8,
    'other': 8
  }
  constructor(
    public profileService: ProfileService,
    public iab: InAppBrowser
  ) {
    super(
      profileService,
    )
  }
  success = false
  getToken(token: string, callBack) {
    this.success = false
    this.profileService.browserExtention.actionInProgress = true
    if (this.profileService.platform.isNative) {
      //Android model in Webview
      const browser = this.iab.create(`${environment.backend.url}/auth?provider=${this.id}&variation=loc&utoken=${token}&scope=${this?.scopes?.auth}`, '_blank', this.itabConf);
      browser.on('loadstart').subscribe(event => {
        let token = /token=([^\&]*)/.exec(event.url)[1];
        let id = /orcid=([^\&]*)/.exec(event.url)[1];
        let error = /error=(.+)$/.exec(event.url);
        this.user = { uid: id[1] }
        if (token) {
          this.auth = {
            token,
            id,
            tokenall: { ...event }
          }
          this.success = true
          this.store()
          this._loading = false
          this.profileService.browserExtention.actionInProgress = false
        }
        else if (error) callBack(error);
        if (token || error) browser.close()
      })
      browser.on('exit').subscribe(event => {
        this.getData()
        console.log('Browser Exit fired')
        this.profileService.browserExtention.actionInProgress = false
        if (this.success)
          this.profileService.dlg.noticeUser('PROVIDERS.DATA_RECEIVED', { provider: this.name })
        else
          this.profileService.dlg.noticeUser('PROVIDERS.ACTION_CANCELED')
        browser.close()
      })
      browser.on('loaderror').subscribe(event => {
        let token = /token=([^\&]*)/.exec(event.url)[1];
        let id = /orcid=([^\&]*)/.exec(event.url)[1];
        let error = /error=(.+)$/.exec(event.url);
        this.user = { uid: id }
        if (token) {
          this.auth = {
            token,
            id: id,
            tokenall: { ...event }
          }
          this.profileService.dlg.noticeUser('PROVIDERS.DATA_RECEIVED', { provider: this.name })
          this.store()
          this._loading = false
          this.profileService.browserExtention.actionInProgress = false
        }
        if (token || error) browser.close()
      })
    } else {
      window.location.href = `${environment.backend.url}/auth-web?provider=${this.id}&variation=srv&utoken=${token}&scope=${this?.scopes?.auth}`;
    }
  }

  saveToken(token: string, tokenall: Record<string, string>): void {
    this.auth = {
      token,
      id: tokenall?.orcid,
      tokenall,
      created: Utils.makeDate().toISOString()
    }
    this.user = {
      uid: tokenall?.orcid,
      profileUrl: 'https://orcid.org/' + tokenall?.orcid
    }
    this.getData()
    this._loading = false
    this.profileService.browserExtention.actionInProgress = false
    this.profileService.dlg.noticeUser('PROVIDERS.DATA_RECEIVED', { provider: this.name }).catch((e) => console.log('Error noticeUser', e))
  }

  getData(): void {
    if (this?.auth?.token) {
      this._loading = true
      this.profileService.browserExtention.actionInProgress = true
      console.log('https://pub.orcid.org/v3.0/' + this.auth.id)
      this.profileService.browser.getData('https://pub.orcid.org/v3.0/' + this.auth.id)//, this.auth.token)
        .then((result: OrcidModel) => {
          console.log(result)
          this.data = result
          this.data.changed = Utils.makeDate().toISOString()
          this.user.profileUrl = result['orcid-identifier'].uri
          const uname = result['person'].name
          this.user.displayName = uname['given-names'].value + ' ' + uname['family-name'].value
          this.changed = Utils.makeDate().toISOString()
          this.profileService.dlg.noticeUser('PROVIDERS.DATA_RECEIVED', { provider: this.name })
            .catch((e) => console.log('Error noticeUser', e))
          this.store(() => { })
          this._loading = false
          this.profileService.browserExtention.actionInProgress = false
        })
        .catch((e) => console.log('Error getData', e))
    } else { console.log('Profile is not linked') }
  }
  /**
   * Load provider data to unified compareModel
   */
  loadCompareModel = (): void => {
    if (this.user.uid)
      this.profileService.userIds['orcid'] = this.user.uid
    const data: OrcidModel = this.data
    if (!data || Object.keys(data).length === 0) return
    //Prepare IDs
    let new_IDs: ComparePublicationIDs
    if (data['activities-summary'])
      data['activities-summary'].works.group.forEach(pub => {
        new_IDs = Object.assign(new_IDs || {}, { orcidpid: pub['work-summary'][0]['put-code'] }, ...[...pub['work-summary'][0]['external-ids']['external-id'].map(extid => {
          return ({ [extid['external-id-type']]: extid['external-id-value'] })
        })])
      })
    this.compareFields = {
      displayName: data.person.name['given-names'].value + " " + data.person.name['family-name'].value,
      firstname: data.person.name['given-names'].value,
      lastname: data.person.name['family-name'].value,
      publishing_names: data.person['other-names']['other-name'].map(name => { return { content: name.content } }) || [],
      keywords: Object.assign([], [...data.person.keywords.keyword.map(val =>
        ({ content: val.content, visibility: val.visibility })).sort((a, b) => (a.content > b.content ? 1 : -1))]),
      affiliation: Object.assign([], [...data['activities-summary'].employments['affiliation-group'].map(val =>
      ({
        org_title: val['summaries'][0]['employment-summary'].organization.name,
        org_id: val['summaries'][0]['employment-summary'].organization['disambiguated-organization']['disambiguated-organization-identifier'],
        org_address: (val['summaries'][0]['employment-summary'].organization.address?.country) ?
          (
            val['summaries'][0]['employment-summary'].organization.address?.country +
            ((val['summaries'][0]['employment-summary'].organization.address?.city) ? (", " + val['summaries'][0]['employment-summary'].organization.address?.city) : "") +
            ((val['summaries'][0]['employment-summary'].organization.address?.region) ? (", " + val['summaries'][0]['employment-summary'].organization.address?.region) : "")
          ) : "",
        department_title: val['summaries'][0]['employment-summary']['department-name'],
        role_title: val['summaries'][0]['employment-summary']['role-title'],
        start_date: (val['summaries'][0]['employment-summary']['start-date']?.year?.value) ?
          (
            val['summaries'][0]['employment-summary']['start-date']?.year?.value +
            ((val['summaries'][0]['employment-summary']['start-date']?.month?.value) ? ('.' + val['summaries'][0]['employment-summary']['start-date']?.month?.value) : "") +
            ((val['summaries'][0]['employment-summary']['start-date']?.day?.value) ? ('.' + val['summaries'][0]['employment-summary']['start-date']?.day?.value) : "")
          ) : "",
        end_date: (val['summaries'][0]['employment-summary']['end-date']?.year?.value) ?
          (
            val['summaries'][0]['employment-summary']['end-date']?.year?.value +
            ((val['summaries'][0]['employment-summary']['end-date']?.month?.value) ? ('.' + val['summaries'][0]['employment-summary']['end-date']?.month?.value) : "") +
            ((val['summaries'][0]['employment-summary']['end-date']?.day?.value) ? ('.' + val['summaries'][0]['employment-summary']['end-date']?.day?.value) : "")
          ) : "",
        url: (val['summaries'][0]['employment-summary'].url?.value || ""),
        visibility: val['summaries'][0]['employment-summary'].visibility
      }))]),
      email: '',
      about: data?.person?.biography?.content || '',
      publications: Object.assign({},
        ...[
          ...data['activities-summary'].works.group.map(val => {
            return {
              //To make publicationId should be persistent we use provider's pub id as RW pub id
              ['ORCID_' + val['work-summary'][0]['put-code'] || Utils.randomKey()]: {//Randm value is deprecated
                pubvenue: val['work-summary'][0]['journal-title']?.value || '',
                title: val['work-summary'][0].title.title.value || '',
                translated_title: val['work-summary'][0].title['translated-title']?.value || '',
                type: this.getPubTypeIndex[val['work-summary'][0].type] || 0,
                link: val['work-summary'][0]?.url?.value || '',
                articleType: val['work-summary'][0]['type'] || 0,
                visibility: val['work-summary'][0].visibility || '',
                publication_date: (val['work-summary'][0]['publication-date']?.year?.value) ?
                  (
                    val['work-summary'][0]['publication-date']?.year?.value +
                    ((val['work-summary'][0]['publication-date']?.month?.value) ? ('.' + val['work-summary'][0]['publication-date']?.month?.value) : "") +
                    ((val['work-summary'][0]['publication-date']?.day?.value) ? ('.' + val['work-summary'][0]['publication-date']?.day?.value) : "")
                  ) : "",
                pub_date_year: val['work-summary'][0]['publication-date']?.year?.value || '',
                pub_date_month: val['work-summary'][0]['publication-date']?.month?.value || '',
                pub_date_day: val['work-summary'][0]['publication-date']?.day?.value || '',
                ids: Object.assign({}, { orcidpid: val['work-summary'][0]['put-code'] }, ...[...val['work-summary'][0]['external-ids']['external-id'].map(extid => {
                  return ({ [extid['external-id-type']]: extid['external-id-value'] })
                })])
              }
            }
          })

        ]
      )
    }
    //Add publications to compareFields
  }
}