import { ComparePublicationIDs } from '../../compare/compare.model'
export class PublonsModel {
  accountID: string
  changed?: string
  avatar?: string
  displayName?: string
  firstname?: string
  middlename?: string
  lastname?: string
  publishing_names?: string[]
  keywords?: string
  degree?: string
  partial?: boolean = true
  affiliation?: {
    professionalInstitution?: string
    professionalDepartment?: string
    professionalPosition?: string
  }
  email?: string
  aboutme?: string
  publications?: {
    next?: string,
    previous?: string,
    results: PublonsPublication[]
  }
  profileurl?: string
  indexes?: {
    hIndex?: number
    timesCited?: number
    numPublicationsInWos?: number
    averagePerYear?: number
    averagePerItem?: number
  }
  ids?: PublonsUserIds
}

export class PublonsUserIds {
  api?: string
  id?: number
  orcid?: string
  rid?: string
  rids?: string[]
  truid?: string
  url?: string

}
export class PublonsPublication {
  ids?: PublonsIds  //TODO. Adopt to ComparePublicationIDs
  publication: {
    date_published?: string
    title?: string
    ids?: {
      api: string
      arXiv: string
      doi: string
      id: string
      pmid: string
      ut: string
      url: string
    }
  }
  journal?: {
    ids: {
      id: number
      eissn: string
      issn: string
      url: string
    }
    name: string
  }
  publisher?: {
    ids: {
      id: number
      url: string
    }
    name: string
  }
  author?: {
    api: string
    id: number
    orcid: string
    rid: string
    rids: Array<string>
    truid: string
    url: string

  }
  translated_title?: string
  // Obtain types type?: number
}

export class PublonsIds {
  api: string
  id: string
}
export class PublonsAuthors {
  id: string
  fullName: string
}

export class PublonsPublicationID {
  id_type: string
  id_value: string
}