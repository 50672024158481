import { Injectable } from '@angular/core'
import { Observable, Subscription, BehaviorSubject, throwError } from 'rxjs';

import { ProviderModel } from './provider.model'
import { compareTwoStrings } from 'string-similarity'

import { FacebookService } from './facebook/facebook.service'
import { GoogleService } from './google/google.service'
import { OrcidService } from './orcid/orcid.service'
import { ResearchgateService } from './researchgate/researchgate.service'
import { ScholarService } from './scholar/scholar.service'
import { PublonsService } from './publons/publons.service'
import { ScopusService } from './scopus/scopus.service'
import { ElibraryService } from './elibrary/elibrary.service'
import { ProfileModel } from '../profile/profile.model';
import { ProfileService, CurrentUser } from '../profile/profile.service'
import { ProviderService } from './provider.service';
import { GroupModel } from './groups.model'
import { Utils } from '../components/utils';
import { SettingsService } from '../settings/settings.service'
import { SettingsModel } from '../settings/settings.model';

@Injectable({
    providedIn: 'root'
})
export class ProvidersService {
    subscriptions: Subscription
    list: Array<ProviderService>
    selectedProvider: string //runtime value
    item: {
        orcid?: OrcidService,
        facebook?: FacebookService,
        google?: GoogleService,
        researchgate?: ResearchgateService,
        //scopus?: ScopusService,
        scholar?: ScholarService,
        publons?: PublonsService,
        elibrary?: ElibraryService
    } = {}
    groups: GroupModel
    groupsToStore: boolean //flag of unsaved publication group changes
    groups$: BehaviorSubject<any>
    constructor(
        public orcidService: OrcidService,
        public facebookService: FacebookService,
        public googleService: GoogleService,
        public researchgateService: ResearchgateService,
        //public scopusService: ScopusService,
        public publonsService: PublonsService,
        public scholarService: ScholarService,
        public elibraryService: ElibraryService,
        public profileService: ProfileService,
        public settings: SettingsService
    ) {
        this.groups = new GroupModel
        this.list = [
            orcidService,
            researchgateService,
            scholarService,
            publonsService,
            //scopusService,
            googleService,
            facebookService,
            elibraryService
        ]
        this.list.forEach(el => this.item[el.id] = el)
        this.groupsToStore = false
        this.groups$ = new BehaviorSubject(subscriber => {
            this.profileService.getProfileData().then(profile => {
                console.log('dataLoad')
                this.load(profile)
                subscriber.next(this.groups)
            })
        })
    }

    /**
     * Load profile data from datasource to providers properties 
     * @param profile - data from datasource
     * @returns - void
     * TODO Consistency. Exclude links in groups to absent publications
     */
    public load(profile: ProfileModel): void {
        //Get data from Firestore
        this.list.forEach(provider => {
            if (!profile[provider.id]) return
            //clear old data
            provider = Object.assign(provider, {
                userPic: "",
                archive: false,
                changed: '',
                user: {},
                data: {}
            })
            delete provider.auth
            //update by new data
            if (profile[provider.id]) {
                provider = Object.assign(provider, {
                    id: profile[provider.id].id,
                    name: profile[provider.id].name,
                    site: profile[provider.id].site,
                    ...(profile[provider.id].userPic) && {
                        userPic: profile[provider.id].userPic
                    },
                    archive: profile[provider.id].archive,
                    ...(profile[provider.id].changed) && { changed: profile[provider.id].changed },
                    ...(profile[provider.id].user) && {
                        user: {
                            ...(profile[provider.id].user)
                        }
                    },
                    ...(profile[provider.id].auth) && {
                        auth: {
                            ...(profile[provider.id].auth)
                        }
                    },
                    ...(profile[provider.id].data) && {
                        data: {
                            ...(profile[provider.id].data)
                        }
                    }
                })
                if (provider.userPicTmpl)
                    provider.userPic = provider.userPicTmpl().toString()
                else provider.userPic = provider.user?.photoURL
                provider.loadCompareModel()
            }
            provider.lastLogin = Utils.parseISOString((provider.auth && provider.auth['created']) ? provider.auth['created'] : '1074-01-17T07:06:07.284Z')
        })
        this.groups = profile.groups
        if (!this.groups)
            this.groups = {
                ofPublications: {
                    list: {},
                    keys: [],
                    provider: {}
                }
            }
        //TODO make reorder and custom order
        //this.list.sort((a, b) => (a.name < b.name ? 1 : -1))
        this.prepareGroups()
        console.log(this)
    };

    /**
     * Get all providers data to export
     * @returns 
     */
    getAllProviderData() {
        let result = {}
        this.list.forEach(provider => result[provider.id] = provider.getProviderData())
        result['groups'] = this.groups
        return result
    }

    /**
     * Prepare groups to store.
     * Storing and retriving groups data from server could take some time. To avoid large traffic we just open a flag groupsToStore. Use groupsStore() to send groups data to server.
     */
    prepageGroupsToStore() {
        this.groupsToStore = true
        this.prepareGroups()
    }

    /**
     * Store groups data to server. On runtime better to use prepageGroupsToStore()
     */
    groupsStore() {
        this.prepareGroups()
        this.profileService.updateProfile({
            isShell: false,
            groups: this.groups
        })
        this.groupsToStore = false
    }

    /**
     * join group to selected provider. Move selectedProvider from destinationGroupId to sourceGroupId
     * @param destinationGroupId 
     * @param sourceGroupId
     * TODO make undoAction. Nou use unlink use case
     */
    joinGroupToSelectedProvider(destinationGroupId, sourceGroupId) {
        this.groups.ofPublications.list[sourceGroupId].items.push(
            ...this.groups.ofPublications.list[destinationGroupId].items
        )

        this.groups.ofPublications.list[sourceGroupId].ids.push(
            ...this.groups.ofPublications.list[destinationGroupId].ids.filter(dstId => {
                this.groups.ofPublications.list[sourceGroupId].ids.filter(srcId => (dstId.type !== srcId.type && dstId.value !== srcId.value))
            })

        )
        this.groups.ofPublications.list[sourceGroupId].items.forEach(item => {
            this.item[item.provider].compareFields.publications[item.id].ids.rwgid = sourceGroupId
        })
        delete this.groups.ofPublications.list[destinationGroupId]
        this.prepageGroupsToStore()
    }

    /**
     * Creates new group of publications from copy of group id
     * @param groupId - group id of publications
     * TODO providers properties unifification and conflicts avoid. Now take properties from the first publication in the group
     */
    addGroupToSelectedProvider(groupId) {
        let selectedGropupFirstProvider = this.groups.ofPublications.list[groupId].items[0]
        let sourcePublication = this.item[selectedGropupFirstProvider.provider].compareFields.publications[selectedGropupFirstProvider.id]
        console.log(groupId, selectedGropupFirstProvider, sourcePublication, this.selectedProvider)
        let newPubId = Utils.randomKey()
        this.item[this.selectedProvider].compareFields.publications[newPubId] = sourcePublication
        this.item[this.selectedProvider].compareFields.publications[newPubId].undo = {
            actionName: "ADD_GROUP_TO_SELECTED_PROVIDER",
            data: {
                selectedProvider: this.selectedProvider,
                newPubId,
                groupId
            }
        }
        this.groups.ofPublications.list[groupId].items.push({ provider: this.selectedProvider, id: newPubId })
        //store newPublication
        if (!this.groups.ofPublications.list[groupId].newPublications)
            this.groups.ofPublications.list[groupId]['newPublications'] = {}
        if (!this.groups.ofPublications.list[groupId].newPublications[this.selectedProvider])
            this.groups.ofPublications.list[groupId].newPublications[this.selectedProvider] = {}
        this.groups.ofPublications.list[groupId].newPublications[this.selectedProvider][newPubId] = JSON.stringify(this.item[this.selectedProvider].compareFields.publications[newPubId])
        this.prepageGroupsToStore()
    }

    prepareGroups() {
        //PREPARE GROUPS
        //Iterate providers
        Object.entries(this.list).forEach(([providerId, provider]) => {
            //If publication exists
            if (provider?.compareFields?.publications) {
                //Iterate publications
                Object.entries(provider.compareFields.publications).forEach(([publicationId, publication]) => {
                    //If group is not defined in the publication
                    //Add newPublications data. TODO Move to addGroupToSelectedProvider = addPub to selectedProvider
                    Object.entries(this.groups.ofPublications.list).forEach(([groupId, value]) => {
                        if (value?.newPublications && value.newPublications[provider.id]) {
                            if (Object.entries(value.newPublications[provider.id]).length > 0) {
                                Object.entries(value.newPublications[provider.id]).forEach(([pubId, pubValue]) => {
                                    let newPud = JSON.parse(pubValue.toString())
                                    this.item[provider.id].compareFields.publications[pubId] = newPud
                                })
                            }
                        }
                    })
                    if (!publication.ids['rwgid']) {
                        //Restore rwgid in compare fields if it is already exists in group
                        Object.entries(this.groups.ofPublications.list).forEach(([groupId, group]) => {
                            //Try to find stored group-pub link in groupd. Iterate items 
                            if (!publication.ids['rwgid'])
                                group.items.forEach(item => {
                                    if (item.provider == provider.id && item.id == publicationId) {
                                        console.log('found group', groupId)
                                        publication.ids['rwgid'] = groupId
                                    }
                                })
                            //If group-pub link is not stored try to obtain correlations
                            //Try to find same uid. Iterate ids
                            if (!publication.ids['rwgid'])
                                group.ids.forEach(id => {
                                    if (this.idIsUniqueForPaper(id.type) &&
                                        publication?.ids[id.type] &&
                                        publication?.ids[id.type] == id.value)
                                        publication.ids['rwgid'] = groupId
                                })
                            //Try to find same name in pubs of destination group
                            if (!publication.ids['rwgid'])
                                group.items.forEach(item => {
                                    let pubTitle = this.item[item.provider].compareFields.publications[item.id].title
                                    if (publication.title == pubTitle) publication.ids['rwgid'] = groupId
                                })
                            //Try to find close name in pubs of destination group
                            if (!publication.ids['rwgid'])
                                group.items.forEach(item => {
                                    let pubTitle = this.item[item.provider].compareFields.publications[item.id].title
                                    let similarity = compareTwoStrings(publication.title, pubTitle);
                                    //TODO set the similarity to settings
                                    if (similarity > this.settings.similarity) publication.ids['rwgid'] = groupId
                                })
                        })
                    }
                    //If group-pub link is not stored or not obtained by correlations - create new group
                    if (!publication.ids['rwgid'])
                        publication.ids['rwgid'] = 'RWG.' + Utils.randomKey()
                    if (!this.groups.ofPublications.list[publication.ids['rwgid']]) {
                        //If group is not exists - create group, add ids
                        this.groups.ofPublications.list[publication.ids['rwgid']] = { ids: [], items: [] }
                        this.groups.ofPublications.list[publication.ids['rwgid']].ids = Object.entries(publication.ids)
                            .filter(([key, value]) => { return (this.groups.ofPublications.list[publication.ids['rwgid']].ids.filter(id => (id.type !== key && id.value !== publication.ids[key]))) }).map(([key, value]) => {
                                return { type: key, value: value }
                            });
                    }
                    //Add pub to the group items
                    if (this.groups.ofPublications.list[publication.ids['rwgid']].items.filter(item => { return (item.provider == provider.id && item.id == publicationId) }).length == 0)
                        this.groups.ofPublications.list[publication.ids['rwgid']].items.push({ provider: provider.id, id: publicationId })


                })
            }
        })
        //Make the groups by provider index
        this.list.forEach(provider => this.updateGroupsByProviderIndex(provider.id))
        //Update unified publication info for each group
        Object.entries(this.groups.ofPublications.list).forEach(([groupId, group]) => this.updatePubUnifiedInfo(groupId, group))
        this.groups$.next(this.groups)
    }

    /**
     * Update unified publication info. If correct pub data is undefined we select any value. 
     * We unify data from different providers. 
     * TODO Select not first but quantyty of equal or from provider with higher rank
     * TODO: Change right (галочка) value in the group (updatePubUnifiedInfo) only for deviation type = 2
     * TODO: ignore/accept difference
     * Show unified value on general comparision page 
     * @param groupId 
     * @param group 
     */
    updatePubUnifiedInfo(groupId, group) {
        //TODO. There could be unsupported fields of provider's publicatins type

        //this.groups.ofPublications.list[groupId]
        //Compare full equality fields
        let fields = ['type', 'title', 'journal_title', 'pubvenue', 'volume', 'issue', 'pages', 'pub_date_year', 'pub_date_month', 'pub_date_day']
        group.items.forEach((item) => {
            //Compare fields as strings
            fields.forEach(fieldName => {
                //init correct value
                if (!group?.correct)
                    group['correct'] = { 'title': '' }
                group.correct['doi'] = this.groups.ofPublications.list[groupId].ids.filter(i => i.type == 'doi')[0]?.value
                if (!group.correct[fieldName]) {
                    group.items.forEach((groupItem) => {
                        let fieldValue = this.item[groupItem.provider].compareFields.publications[groupItem.id][fieldName]
                        if (fieldValue)
                            group.correct[fieldName] = fieldValue
                    })
                }
                group.correct['date'] = group.correct['pub_date_year'] ? (group.correct['pub_date_year'] +
                    (group.correct['pub_date_month'] ? ('-' + group.correct['pub_date_month']) : '') +
                    (group.correct['pub_date_day'] ? ('-' + group.correct['pub_date_month']) : ''))
                    : ''
                //init deviation key for the provider if not exists
                if (!group?.deviation)
                    group['deviation'] = { [item.provider + '.' + item.id]: {} }
                if (!group.deviation[item.provider + '.' + item.id])
                    group.deviation[item.provider + '.' + item.id] = {}
                //If correct value is still empty so all values are epty. Deviation = 0
                if (!group.correct[fieldName]) {
                    group.deviation[item.provider + '.' + item.id][fieldName] = 0
                } else if (!this.item[item.provider].compareFields.publications[item.id][fieldName]) {
                    //Value not filled
                    group.deviation[item.provider + '.' + item.id][fieldName] = 1
                } else {
                    //save difference
                    group.deviation[item.provider + '.' + item.id][fieldName] =
                        //TODO Compare case insensitive
                        (group.correct[fieldName] ===
                            this.item[item.provider].compareFields.publications[item.id][fieldName]) ? 0 : 2
                }

            })
            //Compare dates

            //compare authors
            //Count providers in group in case of hasduplicate
            if (group.items.filter(el => el.provider == item.provider).length > 1)
                group.deviation[item.provider + '.' + item.id]['hasduplicate'] = true
        })
    }
    //unique id for publications
    idIsUniqueForPaper(id_type) {
        return ['doi', 'rgpid', 'eid', 'orcidpid', 'rpid'].indexOf(id_type) > -1
    }

    /**
    * Prepare this.groups.ofPublications.provider[provider] index data
    * key current - groups this publications of the provider
    * key other - other groups of publications
    * exclude unsupported groups for the provider
    * Used on add publication or undo
    * @param groupId - group id of publications
    * TODO. Sort by date, custom
    */
    updateGroupsByProviderIndex(provider) {
        if (!this.groups.ofPublications.provider)
            this.groups.ofPublications['provider'] = {}
        this.groups.ofPublications.provider[provider] = {
            current: {}, other: {}, currentLength: 0, otherLength: 0
        }
        let pubData = this.groups.ofPublications.provider[provider]
        Object.entries(this.groups.ofPublications.list).forEach(([groupID, groupValue]) => {
            if ((groupValue.items.map(item => { return item.provider == provider }).indexOf(true)) > -1) {
                pubData.current[groupID] = groupValue
            } else
                pubData.other[groupID] = groupValue
        })
        pubData.currentLength = Object.entries(pubData.current).length
        pubData.otherLength = Object.entries(pubData.other).filter(([key, value]) => {
            return !(value['unsupported'] || {}).hasOwnProperty(provider)
        }).length
    }

    /**
     * Make undo of the action. Type of action defined in undoData['actionName']
     * Types:
     * ADD_GROUP_TO_SELECTED_PROVIDER - undo of this.addGroupToSelectedProvider()
     * @param undoData - undo data object (type of the object depends of actionName)
     */
    undoAction(undoData) {
        if (undoData['actionName'] == 'ADD_GROUP_TO_SELECTED_PROVIDER') {
            console.log(this.item[undoData.data.selectedProvider].compareFields.publications[undoData.data.newPubId])
            //delete undo key
            delete this.item[undoData.data.selectedProvider].compareFields.publications[undoData.data.newPubId]
            this.groups.ofPublications.list[undoData.data.groupId].items.forEach((item, index) => {
                if (item.provider == undoData.data.selectedProvider)
                    this.groups.ofPublications.list[undoData.data.groupId].items.splice(index, 1)
            })
            //Delete newPublication froum group
            delete this.groups.ofPublications.list[undoData.data.groupId].newPublications[undoData.data.selectedProvider][undoData.data.newPubId]
            this.prepageGroupsToStore()
        }
    }

    /**
     * Quantity of unsaved changes of provider. Used 
     * @param providerId - provider id
     * @returns quantity of unsaved changes
     * TODO Changes in publications
     * TODO Changes should be counted by provider itself concerning its type
     */
    providerHasUnsavedChanges(providerId, type = false) {
        if (!providerId) return false
        if (!type) {
            let reg = this.item[providerId]?.compareFieldsBackup
            var count = 0;
            for (var o in reg) if (reg.hasOwnProperty(o)) ++count;
            return count
        }
    }

    /**
     * App.components helper function
     * @returns Quantity of linked providers 
     */
    linkedProovidersCount() {
        let linked = this.list.filter(pr => { return pr.hasOwnProperty('auth') }).length
        let total = this.list.length
        let archive = this.list.filter(pr => { return pr.archive }).length
        let rest = total - linked
        let toadd = total - archive
        return { linked, total, rest, archive, toadd }
    }

    /**
     * App.components helper function
     * @returns percentage of profiles being synced. If no providers returns "?" 
     */
    syncStatus() {
        let status = 0
        let count = 0
        this.list.forEach(provider => {
            let curStatus = provider.getStatus()
            if (curStatus) {
                status += curStatus
                count++
            }
        })
        return this.linkedProovidersCount().linked == 0 ? "?" : Math.round(status * 100 / count) / 100
    }

    groupHasIssues(groupId) {
        let critical = 0
        let total = 0
        let deviation = this.groups.ofPublications.list[groupId].deviation
        Object.entries(deviation).forEach(([providerId, issue]) => {
            if (issue.hasduplicate) critical++
            if (issue.hasduplicate) total++
            if (issue.pubvenue == 2) critical++
            if (issue.pubvenue == 2 || issue.pubvenue == 0) total++
            if (issue.pub_date_year == 0) total++
        })
        return { critical, total }
    }

    groupAllIssues(groupId) {

    }

    issuesCount() {
        let critical = 0
        let total = 0
        Object.entries(this.groups.ofPublications.list).forEach(([groupId, group]) => {
            let issues = this.groupHasIssues(groupId)
            critical += issues.critical
            total += issues.total
        })
        return { critical, total }
    }

}
