import { Injectable } from '@angular/core';
import { ProviderService } from '../provider.service'
import { ProfileService } from '../../profile/profile.service';
import { ElibraryModel, ElibraryPublication } from './elibrary.model'
import { RemoteAction } from '../provider.model'

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { CompareModel, CompareFieldsModel, ComparePublicationIDs } from '../../compare/compare.model'
import { Utils } from '../../components/utils'

@Injectable()
export class ElibraryService extends ProviderService {
  id = 'elibrary'
  name = "eLibrary"
  topPic = `${this.baseHref}assets/providers/${this.id}/topPic.png`
  customIcon = `${this.baseHref}assets/providers/${this.id}/icone.svg`
  site = "elibrary.ru"
  loginConf = {
    login: {
      url: 'https://www.elibrary.ru/author_info.asp?isold=1'
    },
    next: {
      url: 'https://www.elibrary.ru/author_info.asp?isold=1',
      action: 'GetData'
    }
  }
  userPic = ''
  data: ElibraryModel

  /**
  * Index based on Google Scholar
  * 0 - journal
  * 1 - book
  * 3 - conference
  * 4 - chapter
  * 5 - book-review - ORCID
  * 8 - other
  * 9 - thesis
  * 12 - patent
  * 17 - courtcase
  */

  getPubTypeIndex = {
    'article': 0,
    'book': 1,
    'inCollection': 4,  //Use RG key
    'chapter': 4,       //Use RG value
    'code': 8,
    'inProceedings': 3,
    'conference-paper': 3,  //use RG value. Lowercase, replace space to dash
    'coverPage': 8,         //key
    'cover-page': 8,        //value. Lowercase, replace space to dash
    'dataset': 8,           //key
    'data': 8,              //value
    'experimentFindings': 8,  //key
    'experiment-findings': 8, //value
    'method': 8,
    'negativeResults': 8,     //key
    'negative-results': 8,    //value
    'patent': 12,
    'poster': 8,
    'preprint': 0,
    'presentation': 8,
    'rawData': 8,             //key
    'raw-data': 8,            //value
    'researchProposal': 8,
    'research-proposal': 8,
    'technicalReport': 3,
    'technical-report': 3,
    'thesis': 9
  }

  constructor(
    public profileService: ProfileService,
    public iab: InAppBrowser
  ) {
    super(
      profileService,
    )
    this.data = new ElibraryModel
    this.profileService.browserExtention.hostActions[this.id + 'Logged'] = this._logged
    this.profileService.browserExtention.hostActions[this.id + 'Login'] = this._login

    //Store data from extantion
    this.profileService.browserExtention.hostActions[this.id + 'GetData'] = () => {
      let result = this.profileService.browserExtention.extentionData
      console.log(result)
      if (!result['login']) {
        //Confirmation (Login|cancel)
        this.profileService.dlg.showTransConfirmation(['EXT.PLEASE_LOGIN_TITLE', 'EXT.PLEASE_LOGIN_INFO'], { provider: this.name },
          () => {  //Cancel
            this.profileService.dlg.noticeUser('PROVIDERS.ACTION_CANCELED')
            this._loading = false
            this.profileService.browserExtention.actionInProgress = false
          },
          () => {
            this.initLoginFromWeb()
          })
        this.cancelSession()
        return
      }
      //Load data (Update || keep || default)
      //General section
      let profileURL = result['id'] ? 'https://www.elibrary.ru/author_items.asp?authorid=' + result['id'] : null
      this.user = {
        uid: result['accountID'] || this.user.uid || '',
        displayName: result['name'] || this.user.displayName || '',
        profileUrl: profileURL || this.user.profileUrl || ''
      }
      this.auth = {
        token: result['accountID'] || '',
        created: Utils.makeDate().toISOString(),
        tokenall: {}
      }
      //publications
      /**
       * Copy old values
       * Find new record in old list and copy to new object
       * If none create new object and add to the new object
       * replace old data with new object (by properties)
       * TODO mark new and save deleted data
       * TODO Load publications
       */
      let accountID = result['accountID'] || this.data.accountID || ''
      this.data['publications'] ??= {}
      let newPubData: { [pubid: string]: ElibraryPublication } = {}
      let oldPubData: { [pubid: string]: ElibraryPublication } = Object.assign({}, this.data.publications)
      this.data = {
        ...this.data,
        ...{
          accountID: accountID,
          displayName: result['name'] || '',
          surnameeng: result['surnameeng'] || '',
          surnameold: result['surnameold'] || '',
          birth: {
            bday: result['bday'] || '',
            bmonth: result['bmonth'] || '',
            byear: result['byear'] || ''
          },
          grnit: result['grnti'] || {},
          ids: result['ids'] || {},
          affiliation: result['affiliation'],
          keywords: result['keywords'],
          vuzname: result['vuzname'],
          vuzdepname: result['vuzdepname']
        }
      }
      this.data.changed = Utils.makeDate().toISOString()
      this.data.ids['spin'] = accountID
      this.data.ids['elid'] = result['id']
      console.log(this.data)
      this.store()
      this._loading = false
      this.profileService.browserExtention.actionInProgress = false
    }
  }

  loadCompareModel = () => {
    let data = this.data
    this.compareFields = {
      displayName: data.displayName,
      affiliation: [{
        org_title: Utils.getValueSafer(data, ['affiliation','orgname']),
        department_title: Utils.getValueSafer(data, ['affiliation','orgdepname']),
        role_title: Utils.getValueSafer(data, ['affiliation','post'])
      }],
      keywords: Object.assign([], [...(data?.keywords || '').split(',').map(val => ({ content: val.trim() })).sort((a, b) => (a.content > b.content ? 1 : -1))]),
      //publishing_names: data.affiliation['surnameeng']
      //We already prepared publication data peresentation
    }
    this.compareFields['publishing_names'] = []
    if (data['surnameeng']) this.compareFields['publishing_names'].push({ content: data['surnameeng'] })
    if (data['surnameold']) this.compareFields['publishing_names'].push({ content: data['surnameold'] })
  }

  getToken(token: string, then) {
    this.remoteActions = [{
      id: this.id + 'GetData',
      url: 'https://www.elibrary.ru/author_info.asp?isold=1',
      data: '',
      hide: !this.settings.devmode
    }
    ]
    this.firstRemoteAction = this.id + 'GetData'
    this.initLoginFromWeb()
  }

  getData() {
    this.remoteActions = [{
      id: this.id + 'GetData',
      url: 'https://www.elibrary.ru/author_info.asp?isold=1',
      data: '',
      hide: !this.settings.devmode
    }]
    this.firstRemoteAction = this.id + 'GetData'
    //Check auth state
    if (Utils.dateDiffInDays(this.lastLogin, Utils.makeDate()) < this.sessionExpiredDays)
      this.getFromWeb(this.remoteActions, this.firstRemoteAction)
    else
      //Session expired
      this.initCheckAuthFromWeb()
  }

  getStatus() {
    return 10
  }
}