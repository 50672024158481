import { CompareFieldsModel } from '../compare/compare.model'
import { FacebookModel } from './facebook/facebook.model'
import { GoogleModel } from './google/google.model'
import { OrcidModel } from './orcid/orcid.model'
import { ResearchgateModel } from './researchgate/researchgate.model'
import { ScholarModel } from './scholar/scholar.model'
import { PublonsModel } from './publons/publons.model'
import { ScopusModel } from './scopus/scopus.model'
import { ElibraryModel } from './elibrary/elibrary.model'
export class ProviderModel {
    userPicTmpl?: () => string

    id: string                  //Provider short name constant
    name: string                //Provider full name constant
    topPic: string              //RW constant
    icon?: string               //RW constant
    customIcon?: string         //RW constant
    site: string                //Provider constant
    changed?: string
    _loading?: boolean          //Runtime indicator of loading external data process
    scopes?: {                  //API interface constant from provider
        auth: string
    }
    auth?: {                    //API tokens
        token: string
        id?: string
        created?: string
        tokenall: Record<string, unknown>
    }
    archive?: boolean
    user?: {                  //User personal data
        uid: string
        displayName?: string
        email?: string
        photoURL?: string       //Link to user avater on provider server
        profileUrl?: string
    }
    userPic?: string            //Link to user avater on provider server with token
    data?: OrcidModel | FacebookModel | GoogleModel | ResearchgateModel | ScholarModel | PublonsModel | ElibraryModel               //Provider's data
    userData: {                 //User's custom data
        mapPub?: {
            [rwPubId: string]: string //Map rwPubId - providerPubId
        }
    }
    //Calculated
    lastLogin?: Date
    //Constants
    sessionExpiredDays?: number //Constant of session expiration period in days
    loginConf: {
        login: {
            url: string
        },
        next: {
            url: string,
            action: string
        }
    }
    compareFields?: CompareFieldsModel
}

export class RemoteAction {     //Extention message type
    id: string
    url: string
    data?: {}
    hide?: boolean
    login?: boolean
}