import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TabsPage } from './tabs.page';

const routes: Routes = [
  {
    path: '',
    component: TabsPage,
    children: [
      // /app/ redirect
      {
        path: 'compare/:pr',
        children: [
          {
            path: '',
            loadChildren: () => import('../compare/compare.module').then(m => m.CompareModule)
          }
        ]
      },
      {
        path: 'employment/:pr',
        children: [
          {
            path: '',
            loadChildren: () => import('../compare/employment/employment.module').then(m => m.CompareEmploymentModule)
          }
        ]
      },
      {
        path: 'publications/:pr',
        children: [
          {
            path: '',
            loadChildren: () => import('../compare/publications/publications.module').then(m => m.ComparePublicationsModule)
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
  providers: []
})
export class TabsPageRoutingModule { }