export class CompareModel {
    keys: Array<string>
    fields: {
        [key: string]: {
            meta: {
                name: string,
                type: string,
                compareType?: string
            },
            data?: Record<string, unknown>,
            groups?: {
                [value: string]: Array<string>,
            },
            selectedgroup?: Array<string>
            equals?: {
                [value: string]: boolean,
            }
        }
    }
    constructor() {
        this.keys = ['photoURL', 'displayName', 'firstname', 'middlename', 'lastname', 'publishing_names', 'email', 'keywords', 'about']
        this.fields = {
            photoURL: {
                meta: {
                    name: 'COMPARE.PHOTO_URL',
                    type: 'avatar'
                }
            },
            displayName: {
                meta: {
                    name: 'COMPARE.USER_NAME',
                    type: 'label'
                }
            },
            firstname: {
                meta: {
                    name: 'COMPARE.USER_FIRSTNAME',
                    type: 'label'
                }
            },
            middlename: {
                meta: {
                    name: 'COMPARE.USER_MIDDLENAME',
                    type: 'label'
                }
            },
            lastname: {
                meta: {
                    name: 'COMPARE.USER_LASTNAME',
                    type: 'label'
                }
            },
            publishing_names: {
                meta: {
                    name: 'COMPARE.USER_NAME_SYNS',
                    type: 'tags'
                }
            },
            email: {
                meta: {
                    name: 'COMPARE.USER_EMAIL',
                    type: 'label'
                }
            },
            keywords: {
                meta: {
                    name: 'COMPARE.PROFILE_KEYWORDS',
                    type: 'tags'
                }
            },
            about: {
                meta: {
                    name: 'COMPARE.PROFILE_ABOUT',
                    type: 'label'
                }
            },
            publications: {
                meta: {
                    name: 'COMPARE.PROFILE_PUBLICATIONS',
                    type: 'publications'
                }
            },
            affiliation: {
                meta: {
                    name: 'COMPARE.PROFILE_EMPLOYMENT',
                    type: 'affiliations'
                }
            },
            indexes: {
                meta: {
                    name: 'COMPARE.PROFILE_INDEXES',
                    type: 'label'
                }
            }
        }
    }
}

export class CompareFieldsModel {
    photoURL?: string
    displayName?: string
    firstname?: string
    middlename?: string
    lastname?: string
    publishing_names?: CompareFieldTags[]
    email?: string
    about?: string
    keywords?: CompareFieldTags[]
    publications?: { [pubid: string]: ComparePublications }
    affiliation?: CompareAffiliation[]
    indexes?: CompareIndexes
}

export class CompareIndexes {
    h_all?: number
    h_5?: number
    i10_5?: number
    i10_all?: number
    citations_5?: number
    citations_all?: number
}
export class CompareAffiliation {
    org_title: string
    org_id?: string
    org_address?: {
        country: string
        city: string
        region: string
    }
    department_title?: string
    role_title?: string
    start_date?: string
    end_date?: string
    url?: string
    visibility?: string
}

export class ComparePublications {
    rpid?: string
    undo?: () => void
    groupId?: string
    ids?: ComparePublicationIDs
    /**
 * Index based on Google Scholar
 * 0 - journal
 * 1 - book
 * 3 - conference
 * 4 - chapter
 * 5 - book-review - ORCID
 * 8 - other
 * 9 - thesis
 * 12 - patent
 * 17 - courtcase
 */
    type?: number
    fields?: {
        0: ['title', 'translated_title', 'authors', 'pubvenue', 'publication_date', 'volume', 'issue', 'pages', 'publisher'],
        3: ['title', 'translated_title', 'authors', 'pubvenue', 'publication_date', 'volume', 'issue', 'pages', 'publisher'],
        4: ['title', 'translated_title', 'authors', 'pubvenue', 'publication_date', 'volume', 'issue', 'pages', 'publisher'],
        1: ['title', 'translated_title', 'authors', 'pubvenue', 'publication_date', 'volume', 'issue', 'pages', 'publisher'],
        9: ['title', 'translated_title', 'authors', 'pubvenue', 'publication_date', 'publisher'],
        12: ['title', 'translated_title', 'authors', 'patoffice', 'publication_date', 'patclime', 'patnumber'],
        17: ['title', 'translated_title', 'courtcase', 'publication_date', 'volume', 'issue', 'pages', 'publisher'],
        8: ['title', 'translated_title', 'authors', 'publication_date', 'pubvenue', 'volume', 'issue', 'pages', 'publisher']

    }
    //Veryfy fileds correlation. Could we minimize them
    doi?: string
    title: string
    translated_title?: string
    authors?: CompareAuthors[]
    publication_date?: string
    pub_date_year?: string
    pub_date_month?: string
    pub_date_day?: string
    pubvenue?: string
    volume?: string
    issue?: string
    pages?: string
    publisher?: string
    patoffice?: string
    patapplic?: string
    patnumber?: string
    curt?: string
    reporter?: string
    docketid?: string
    abstract?: string
    keywords?: Array<string>
    cited?: number
    link?: string
}

export class ComparePublicationIDs {
    rwgid?: string //Rewrite group id_type
    rgpid?: string //Researchgate publication id
    orcidpid?: string
    doi?: string
    isbn?: string
    issn?: string
    eid?: string
    gspid?: string
}

export class ComparePublicationID {
    id_type: string
    id_value: string
}

export class CompareFieldTags {
    content: string
    visibility?: string
    display_index?: number
}

export class CompareAuthors {
    id?: string
    fullName: string
}