import { ComparePublicationIDs } from '../../compare/compare.model'
export class ScopusModel {
  accountID: string
  changed?: string
  avatar?: string
  displayName?: string
  firstname?: string
  middlename?: string
  lastname?: string
  keywords?: string
  degree?: string
  affiliation?: {
    professionalInstitution?: string
    professionalDepartment?: string
    professionalPosition?: string
  }
  email?: string
  aboutme?: string
  publications?: { [pubid: string]: ScopusPublication }
  profileurl?: string
}

export class ScopusPublication {
  ids?: ComparePublicationIDs
  title: string
  translated_title?: string
  type?: number
  link?: string
  authors?: ScopusAuthors[]
  pages?: string
  publicationDate?: string
  abstract?: string
  keywords?: Array<string>
}

export class ScopusAuthors {
  id: string
  fullName: string
}

export class ScopusPublicationID {
  id_type: string
  id_value: string
}