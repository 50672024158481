/// <reference types="chrome"/>
//TODO manage subscriptions
import { NgZone, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';

import { MenuController, AlertController, Platform } from '@ionic/angular';
import { Location } from '@angular/common';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { ProfileModel } from './profile.model';
import { ProfileService, CurrentUser } from './profile.service';
import { ProvidersService } from '../providers/providers.service'
import { SettingsService } from '../settings/settings.service'
import { Utils } from '../components/utils'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: [
    './styles/profile.page.scss'
  ]
})
export class ProfilePage implements OnInit {
  // Gather all component subscription in one place. Can be one Subscription or multiple (chained using the Subscription.add() method)
  subscriptions: Subscription
  submitError: string
  authRedirectResult: Subscription
  accessToken = {}
  userProfile: ProfileModel
  backgroundPort
  extentionHere = false
  extentionData = {}
  extentionVer = ''
  curAlert: HTMLIonAlertElement
  profileDataSource: Observable<ProfileModel>
  topError = {}
  loadingProgress = {}
  constructor(
    public providers: ProvidersService,
    private route: ActivatedRoute,
    public platform: Platform,
    public router: Router,
    public location: Location,
    public profileService: ProfileService,
    private iab: InAppBrowser,
    public alertController: AlertController,
    public menu: MenuController,
    private currentUser: CurrentUser,
    public settings: SettingsService,
    private zone: NgZone) {

  };
  saveTokenBusy = false
  /**
   * Load/create User profile
   * 
   * Unfortunately we have no data if social login creates new user so we hack a bit
   * User's photoURL field used as indicator
   * If photoURL=='created' - we had created a profile already
   * 
   * @returns void
   */
  ngOnInit() {
    this.menu.enable(true)
    this.profileService.browserExtention.loadingProgress$.subscribe(state => {
      this.loadingProgress = state[0]
      this.zone.run(() => { })
    })
  }

  public scrollToAnchor(location: string, wait = 0): void {
    const element = document.querySelector('#' + location);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, wait);
    }
  }

  saveToken(provider, token, tokenall) {
    this.providers.item[provider].saveToken(token, tokenall)
    this.providers.item[provider].store(() => {
      this.saveTokenBusy = false
      this.router.navigateByUrl("/profile")
    })
  }

  doUnlink(providerId) {
    //TODO Proper logout
    this.profileService.dlg.showTransDialog(() => {
      console.log('unlink', providerId, this.providers.item[providerId])
      this.providers.item[providerId].deleteProfile()
      this.providers.item[providerId].store()
    },
      ['PROFILE.UNLINK_FROM', 'PROFILE.UNLINK_WARNING'], [])
  }

  doLink(providerId) {
    //get user token. TODO error catch
    this.providers.item[providerId].getToken('utoken', result => {
      if (result)
        this.profileService.dlg.showAlert("Result", result)
      else {
        this.saveTokenBusy = false
      }
    })
  }

  getData(providerId) {
    this.providers.item[providerId].getData()
  }

  goCompare(provider: string) {
    if (provider)
      this.router.navigateByUrl("/tabs/compare/" + provider.toLowerCase());
  }


  doDeleteAccount() {
    this.profileService.deleteProfile(this.currentUser.userid)
  }

  // NOTE: Ionic only calls ngOnDestroy if the page was popped (ex: when navigating back)
  // Since ngOnDestroy might not fire when you navigate from the current page, use ionViewWillLeave to cleanup Subscriptions
  ionViewWillLeave(): void {
    if (this.subscriptions)
      this.subscriptions.unsubscribe();
  }

  //Utils

  daysFromNow(from) {
    const fateFrom = new Date(from)
    const dateNow = new Date()
    const timediff = Math.ceil(Math.abs(dateNow.getTime() - fateFrom.getTime()) / (1000 * 60 * 60 * 24))
    return timediff
  }

  doRefresh(event) {
    window.location.reload()
    setTimeout(() => {
      console.log('Async operation has ended');
      event.target.complete();
    }, 2000);
  }

  doArchive(provider: string) {
    this.providers.item[provider].doArchive()
  }

  doRestore(provider: string) {
    this.providers.item[provider].doUnArchive()
    setTimeout(() => {
      this.scrollToAnchor(provider)
    }, 1000);
  }

  providersList(archive: boolean) {
    return this.providers.list.filter(pr => { return (pr.archive || false) === archive })
  }

  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
  nextStep() {
    this.router.navigate(['/analyse'], { replaceUrl: true })
  }

  openExternalPage(url) {
    if (this.profileService.platform.isNative) {
      this['browser'] = this.iab.create(url, '_blank', this.settings.itabConf);
    } else {
      window.open(url, '_blank');
    }
  }
}
