/// <reference types="chrome" />
/**
 * BrowserInterface - get and post data overwrites
 * CurrentUser store and retrieve user data
 * Profile service stores and retrieve profile data communicating local storage
 */
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment'
import { Utils } from '../components/utils'
import { Dialogues } from './dialogs.service'
import { ProfileModel, MyProfilesListModel, ExtentionDataModel, UserDataModel } from './profile.model';
import { StorageService } from '../storage/storage.service'
import { SettingsService } from '../settings/settings.service'

@Injectable({
  providedIn: 'root'
})
export class CurrentUser {
  cache = false
  userid: string
  user$: Observable<UserDataModel | null>
  profileList: MyProfilesListModel

  constructor(
    public storage: StorageService,
    public settings: SettingsService
  ) {
  }


  public userSignOut() {
    //TODO1
    //Delete profiles.list.selected key in storage
  }


  public getProfileList(): Promise<MyProfilesListModel> {
    return this.storage.getUserProfiles()
  }

  public setProfileList(MyNewUserProfileList) {
    return this.storage.setUserProfiles(MyNewUserProfileList)
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  //Here providers runtime share common ID data.
  userIds = {}
  //Progress indicator
  progress: {
    upload: boolean,
    download: boolean
  }
  count = 0
  replacer = {
    userId: '',
    rootUser: ''
  }
  local: boolean
  platform: {
    isNative: boolean
    isDevel: boolean
    incompatible: boolean
    container: string
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    public storage: StorageService,
    public browserExtention: BrowserExtention,
    public browser: BrowserInterface,
    public dlg: Dialogues,
    public currentUser: CurrentUser,
    public settings: SettingsService
  ) {
    this.progress = {
      upload: false,
      download: false
    }
  }

  public createProfileData() {
    const userData: ProfileModel = {
      isShell: true,
      userid: this.currentUser.userid,
    };
    this.setProfile(userData, this.currentUser.userid);
  }

  /**
   * Get data of a specific Profile
   * @param userId 
   * @returns 
   */
  public getProfileData(userId = this.currentUser.userid): Promise<ProfileModel> {
    if (this.settings.alteregoOn && this.settings.alteregoId.length > 3)
      userId = this.settings.alteregoId + '_dev'
    return this.storage.load(userId)
  }

  /**
   * Store complete profile to local storage and FB
   * @param userData - 
   * @param userID - User ID
   * @returns Promise
   */
  public setProfile(userData: ProfileModel, userID = this.currentUser.userid): void {
    this.progress.upload = true
    if (this.settings.alteregoOn && this.settings.alteregoId.length > 3)
      userID = this.settings.alteregoId + '_dev'
    console.log(this.storage, { [userID]: userData })
    this.storage
      .set(userData, userID)
      .catch((e) => console.log('Error storage.setCurrentUser', e, userData, userID))
      .finally(() => {
        this.progress.upload = false
      })
  }

  public updateProfile(userData: ProfileModel, userID = this.currentUser.userid) {
    this.progress.upload = true
    if (this.settings.alteregoOn && this.settings.alteregoId.length > 3)
      userID = this.settings.alteregoId + '_dev'
    return this.storage
      .set(userData, userID)
      .then(e => {
      })
      .catch((e) => console.log('Error storage.setUserData', e, userData, userID))
      .finally(() => {
        this.progress.upload = false
      })
  }

  public checkoutProfile(): void {
    console.log('New checkout model required. Use save make a friend request, link and synch the data using server.')
  }

  public deleteProfile(userId: string): Promise<void> {
    //TODO clear data in memory
    return this.storage.deleteUserData(userId)
  }


}

@Injectable({
  providedIn: 'root'
})
export class BrowserInterface {
  constructor(
    private http: HttpClient) {

  }
  public getData(url: string, token?: string): Promise<any> {
    const parameters = {
      'Accept': 'application/json',//TODO 
      'Content-Type': 'application/json'
    }
    if (token) parameters['Authorization'] = 'Token ' + token
    const httpOptions = {
      headers: new HttpHeaders(parameters)
    };
    return this.http.get<Record<string, unknown>>(url, httpOptions).pipe(first()).toPromise();
  }
}

@Injectable({
  providedIn: 'root'
})
/**
 * BrowserInterface - get and post data overwrites
 */
export class BrowserExtention {
  backgroundPort
  extentionHere: boolean
  extentionData: ExtentionDataModel
  extentionVer: string
  hostActions: Record<string, () => void> = {}
  actionInProgress: boolean
  public loadingProgress: BehaviorSubject<{ [provider: string]: string }[]> = new BehaviorSubject(undefined);
  public loadingProgress$: Observable<{ [provider: string]: string }[]> = this.loadingProgress.asObservable()
  constructor(
    private http: HttpClient,
    private dlg: Dialogues
  ) {
    this.extentionHere = false
    this.extentionData = {}
    this.extentionVer = ''
    this.actionInProgress = false
    this.loadingProgress.next([{}])
  }

  public getInjectJS(url: string): Observable<string> {
    return this.http.get(url, { responseType: 'text' })
  }

  sendMessageToExtention(message) {
    if (this.backgroundPort)
      try {
        this.backgroundPort.postMessage(message)
        return true
      }
      catch {
        console.log('Extention background port failure')
        this.backgroundPort = null
      }
    if (!this.backgroundPort && typeof chrome != "undefined") {
      this.backgroundPort = chrome.runtime.connect(environment.extention.Id)
      //need some time for port to get available
      setTimeout(() => {
        console.log('port', typeof this.backgroundPort)
        this.extentionHere = true
        //init listener
        this.backgroundPort.onDisconnect.addListener(function () {
          this.backgroundPort = null;
        });
        this.backgroundPort.onMessage.addListener((m: ExtentionDataModel) => {
          //Check version
          if (m.version) {
            this.extentionHere = (Utils.cmpVersions(m.version, environment.extention.MinVer) >= 0) ? true : false
            this.extentionVer = m.version
          }
          //get data and action
          console.log(m)
          if (m?.loadingProgress && m?.provider) {
            this.loadingProgress.next([{ [m.provider]: m.loadingProgress }])
          }
          if (m?.data && m?.hostAction) {
            this.extentionData = m.data
            this.hostActions[m.hostAction]()
          }
        });
        if (typeof this.backgroundPort === 'object') {
          this.backgroundPort.postMessage({ request: "version" });
          setTimeout(() => {
            try {
              console.log('start proc', message)
              this.backgroundPort.postMessage(message)
              return true
            }
            catch {
              console.log('Extention background port failure')
              this.backgroundPort = null
            }
          }, 30);
        } else console.log('Extention background port failure')
      }, 30);
      return true
    }
  }


  initExtention() {
    try {
      if (!this.backgroundPort && typeof chrome != "undefined") {
        this.backgroundPort = chrome.runtime.connect(environment.extention.Id)
        //init listener
        this.backgroundPort.onDisconnect.addListener(function () {
          this.backgroundPort = null;
        });
        this.backgroundPort.onMessage.addListener((m: ExtentionDataModel) => {
          //Check version
          if (m.version) {
            this.extentionHere = (Utils.cmpVersions(m.version, environment.extention.MinVer) >= 0) ? true : false
            this.extentionVer = m.version
          }
          //get data
          //console.log(m)
          if (m?.loadingProgress && m?.provider) {
            this.loadingProgress.next([{ [m.provider]: m.loadingProgress }])
          }
          if (m?.data && m?.hostAction) {
            this.extentionData = m.data
            console.log(this.hostActions, this)
            this.hostActions[m.hostAction]()
            if (m.data['result'] === 600) this.dlg.noticeUser('PROVIDERS.ACTION_CANCELED').catch((e) => console.log('Error dlg.noticeUser', e))
            else this.dlg.noticeUser('PROVIDERS.DATA_RECEIVED').catch((e) => console.log('Error dlg.noticeUser', e))
          }
        });
      }
      this.backgroundPort.postMessage({ request: "version" });
    } catch {
      console.log('initExtention runtime error')
      this.extentionHere = false
    }
  }

}
