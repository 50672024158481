import { ComparePublicationIDs } from '../../compare/compare.model'
export class ElibraryModel {
  accountID: string
  changed?: string
  avatar?: string
  displayName?: string
  firstname?: string
  middlename?: string
  lastname?: string
  keywords?: string
  degree?: string
  affiliation?: {
    professionalInstitution?: string
    professionalDepartment?: string
    professionalPosition?: string
  }
  email?: string
  aboutme?: string
  publications?: { [pubid: string]: ElibraryPublication }
  profileurl?: string
  indexes?: {
    h_all: number,
    citations_all: number
  }
  ids?: {
    [id: string]: string
  }
}

export class ElibraryPublication {
  ids?: ComparePublicationIDs
  title: string
  translated_title?: string
  type?: number
  link?: string
  authors?: ElibraryAuthors[]
  pages?: string
  publicationDate?: string
  abstract?: string
  keywords?: Array<string>
}

export class ElibraryAuthors {
  id: string
  fullName: string
}

export class ElibraryPublicationID {
  id_type: string
  id_value: string
}