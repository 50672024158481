import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core';

import { ProviderService } from './provider.service'
import { OrcidService } from './orcid/orcid.service'
import { FacebookService } from './facebook/facebook.service'
import { GoogleService } from './google/google.service'
import { ResearchgateService } from './researchgate/researchgate.service'
import { ScholarService } from './scholar/scholar.service'

@NgModule({
    imports: [
    ],
    declarations: [
    ],
    providers: [
    ]
})
export class ProviderModule { }
