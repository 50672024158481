import { Injectable } from '@angular/core';
import { ProviderService } from '../provider.service'
import { ProfileService } from '../../profile/profile.service';
import { ResearchgateModel, ResearchgatePublication } from './researchgate.model'

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ComparePublicationIDs } from '../../compare/compare.model'
import { Utils } from '../../components/utils'

@Injectable()
export class ResearchgateService extends ProviderService {
  id = 'researchgate'
  name = "Researchgate"
  topPic = `${this.baseHref}assets/providers/${this.id}/topPic.png`
  customIcon = `${this.baseHref}assets/providers/${this.id}/icone.svg`
  site = "researchgate.com"
  loginConf = {
    login: {
      url: 'https://www.researchgate.net/login'
    },
    next: {
      url: 'https://researchgate.net/',
      action: 'GetData'
    }
  }
  userPic = ''
  data: ResearchgateModel

  /**
  * Index based on Google Scholar
  * 0 - journal
  * 1 - book
  * 3 - conference
  * 4 - chapter
  * 5 - book-review - ORCID
  * 8 - other
  * 9 - thesis
  * 12 - patent
  * 17 - courtcase
  */

  getPubTypeIndex = {
    'article': 0,
    'book': 1,
    'inCollection': 4,  //Use RG key
    'chapter': 4,       //Use RG value
    'code': 8,
    'inProceedings': 3,
    'conference-paper': 3,  //use RG value. Lowercase, replace space to dash
    'coverPage': 8,         //key
    'cover-page': 8,        //value. Lowercase, replace space to dash
    'dataset': 8,           //key
    'data': 8,              //value
    'experimentFindings': 8,  //key
    'experiment-findings': 8, //value
    'method': 8,
    'negativeResults': 8,     //key
    'negative-results': 8,    //value
    'patent': 12,
    'poster': 8,
    'preprint': 0,
    'presentation': 8,
    'rawData': 8,             //key
    'raw-data': 8,            //value
    'researchProposal': 8,
    'research-proposal': 8,
    'technicalReport': 3,
    'technical-report': 3,
    'thesis': 9
  }

  constructor(
    public profileService: ProfileService,
    public iab: InAppBrowser
  ) {
    super(
      profileService,
    )
    this.data = new ResearchgateModel
    this.profileService.browserExtention.hostActions[this.id + 'Logged'] = this._logged
    this.profileService.browserExtention.hostActions[this.id + 'Login'] = this._login

    //Get data - hidden
    this.profileService.browserExtention.hostActions[this.id + 'GetData'] = () => {
      let result = this.profileService.browserExtention.extentionData
      console.log(result)
      if (!result['login']) {
        //Confirmation (Login|cancel)
        this.profileService.dlg.showTransConfirmation(['EXT.PLEASE_LOGIN_TITLE', 'EXT.PLEASE_LOGIN_INFO'], { provider: this.name },
          () => {  //Cancel
            this.profileService.dlg.noticeUser('PROVIDERS.ACTION_CANCELED')
            this._loading = false
            this.profileService.browserExtention.actionInProgress = false
          },
          () => {
            this.initLoginFromWeb()
          })
        this.cancelSession()
        return
      }
      //Load data (Update || keep || default)
      //General section
      if (this.user != undefined)
        this.user = {
          uid: result['userid'] || this.user.uid || '',
          displayName: result['name'] || this.user.displayName || '',
          photoURL: result['avatar'] || this.user.photoURL || '',
          profileUrl: result['profileurl'] || this.user.profileUrl || ''
        }
      if (result['userid'])
        this.auth = {
          token: result['userid'],
          created: Utils.makeDate().toISOString(),
          tokenall: {}
        }
      if (result['hindex'])
        result['hindex'] = result['hindex'].trim()
      if (result['citations'])
        result['citations'] = result['citations'].trim()
      this.data.indexes = {
        h_all: result['hindex'] || this.data?.indexes?.h_all || 0,
        citations_all: result['citations'] || this.data.indexes ? this.data.indexes?.citations_all || 0 : 0
      }
      //publications section
      /**
       * Copy old values
       * Find new record in old list and copy to new object
       * If none create new object and add to the new object
       * replace old data with new object (by properties)
       * TODO mark new and save deleted data
       * Add authors management
       * Notice. We obtain all deleted publication on the Provider side by finding goups with lost link to publication.
       */
      let accountID = result['accountID'] || this.data.accountID || ''
      this.data['publications'] ??= {}
      let newPubData: { [pubid: string]: ResearchgatePublication } = {}
      let oldPubData: { [pubid: string]: ResearchgatePublication } = Object.assign({}, this.data.publications)
      //if have old data: Store key: ids
      if (result['pubdetails']) {
        let new_IDs: ComparePublicationIDs
        Object.entries(result['pubdetails']).forEach(([newPublicationID, newPublicationData]) => {
          let curID = Utils.randomKey()
          new_IDs = {
            rgpid: newPublicationData['id'] || '',
            doi: newPublicationData['doi'] || '',
            isbn: newPublicationData['isbn'] || '',
            issn: newPublicationData['issn'] || ''
          }
          Object.entries(oldPubData).forEach(([oldPublicationID, oldPublicationData]) => {
            if (oldPublicationData?.ids && oldPublicationData.ids.rgpid && oldPublicationData.ids.rgpid == new_IDs.rgpid)
              curID = oldPublicationID
          })
          newPubData[curID] = {
            title: newPublicationData['title'] || '',
            type: this.getPubTypeIndex[newPublicationData['concreteType']?.name.toLowerCase() || 'article'],
            //authors?: ResearchgateAuthors[]
            pages: newPublicationData['pages'] || '',
            publicationDate: newPublicationData['publicationDate'] || '',
            abstract: newPublicationData['abstract'] || '',
            keywords: newPublicationData['keywords'] || [],
            ids: { ...oldPubData[curID]?.ids, ...new_IDs } //Here we keep the RE GroupID
          }
        })
      } else { //Keep olda data if none incoming
        newPubData = oldPubData
      }
      this.data = {
        ...this.data,
        ...{
          accountID: accountID,
          displayName: result['name'] || '',
          firstname: result['account_names']?.firstname || '',
          middlename: result['account_names']?.middlename || '',
          lastname: result['account_names']?.lastname || '',
          aboutme: (result['aboutme']) ? result['aboutme'][accountID] || '' : '',
          degree: result['account_names']?.degree || '',
          affiliation: result['affiliation'] || '',
          publications: newPubData
        }
      }
      this.data.changed = Utils.makeDate().toISOString()
      console.log(this.data, this)
      this.store()
      this._loading = false
      this.profileService.browserExtention.actionInProgress = false
    }
  }

  loadCompareModel = () => {
    let data = this.data
    this.compareFields = {
      photoURL: this.user.photoURL,
      displayName: data?.displayName,
      firstname: data.firstname,
      middlename: data.middlename,
      lastname: data.lastname,
      ...(data.affiliation) && {
        affiliation: [{
          org_title: data.affiliation.professionalInstitution,
          department_title: data.affiliation.professionalDepartment,
          role_title: data.affiliation.professionalPosition
        }]
      },
      about: data.aboutme,
      //We already prepared publication data peresentation
      publications: data.publications,
      indexes: {
        h_all: data?.indexes?.h_all,
        citations_all: data?.indexes?.citations_all
      }
    }
  }

  getToken(token: string, then) {
    //Get data
    this.remoteActions = [{
      id: this.id + 'GetData',
      url: 'https://researchgate.net/',
      data: '',
      hide: !this.settings.devmode,
      login: true
    }, {
      id: this.id + 'GetAPIData',
      url: 'https://researchgate.net/',
      data: '',
      hide: !this.settings.devmode,
      login: true
    }]
    this.firstRemoteAction = this.id + 'GetData'
    this.initLoginFromWeb()
  }

  getData() {
    this.remoteActions = [{
      id: this.id + 'GetData',
      url: 'https://researchgate.net',
      data: '',
      hide: !this.settings.devmode,
      login: true
    }, {
      id: this.id + 'GetAPIData',
      url: 'https://researchgate.net',
      data: '',
      hide: !this.settings.devmode,
      login: true
    }
    ]
    this.firstRemoteAction = this.id + 'GetData'
    //Check auth state
    if (Utils.dateDiffInDays(this.lastLogin, Utils.makeDate()) < this.sessionExpiredDays)
      this.getFromWeb(this.remoteActions, this.firstRemoteAction)
    else
      //Session expired
      this.initCheckAuthFromWeb()
  }

  getStatus() {
    return 10
  }
}
