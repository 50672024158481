<ion-content>
    <ion-tabs (ionTabsDidChange)="ionTabsDidChange($event)">
        <ion-tab-bar slot="bottom">
            <ion-tab-button tab="compare/current" [class.tab-selected]="onTab('compare')">
                <ion-icon name="list-outline"></ion-icon>
                <ion-label class="tab-title" translate>COMPARE.PROFILE_GENERAL</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="publications/current" [class.tab-selected]="onTab('publications')" *ngIf="providers.item[providers.selectedProvider]?.compareFields?.publications !== null && providers.item[providers.selectedProvider]?.compareFields.hasOwnProperty('publications')">
                <ion-icon name="person-outline"></ion-icon>
                <ion-label class="tab-title" translate>COMPARE.PROFILE_PUBLICATIONS</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="employment/current" [class.tab-selected]="onTab('employment')" *ngIf="providers.item[providers.selectedProvider]?.compareFields?.affiliation !== null && providers.item[providers.selectedProvider]?.compareFields.hasOwnProperty('affiliation')">
                <ion-icon name="notifications-outline"></ion-icon>
                <ion-label class="tab-title" translate>COMPARE.PROFILE_EMPLOYMENT</ion-label>
            </ion-tab-button>
        </ion-tab-bar>
    </ion-tabs>
</ion-content>