import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './language.service';
import { ProfileService, CurrentUser } from '../profile/profile.service';


@Component({
    selector: 'app-language-chooser',
    templateUrl: './language.component.html',
    styleUrls: [
        './language.component.scss'
    ]
})
export class LanguageShooserComponent implements OnInit {
    available_languages = [];//Language

    constructor(
        public language: LanguageService,
        public alertController: AlertController,
        public profileService: ProfileService,
        private currentUser: CurrentUser
    ) {

    }
    ngOnInit(): void {
    }

    async openLanguageChooser() {
        this.available_languages = this.language.getLanguages()
            .map(item =>
            ({
                name: item.name,
                type: 'radio',
                label: item.name,
                value: item.code,
                checked: item.code === this.language.curLang
            })
            );

        const alert = await this.alertController.create({
            header: this.language.translations.SELECT_LANGUAGE,
            inputs: this.available_languages,
            cssClass: 'language-alert',
            buttons: [
                {
                    text: this.language.translations.CANCEL,
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => { }
                }, {
                    text: this.language.translations.OK,
                    handler: (lng) => {
                        if (lng) {
                            this.language.setLanguage(lng)
                            if (this.currentUser.userid) {
                                this.profileService.updateProfile({
                                    language: lng,
                                    isShell: true
                                })
                            }
                        }
                    }
                }
            ]
        });
        await alert.present();
    }
}