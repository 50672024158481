import { Injectable } from '@angular/core';
import { ProviderService } from '../provider.service'
import { ProfileService } from '../../profile/profile.service';
import { GoogleModel } from './google.model'
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Utils } from '../../components/utils'
import { environment } from '../../../environments/environment'

@Injectable()
export class GoogleService extends ProviderService {
  id = 'google'
  name = "Google"
  topPic = `${this.baseHref}assets/providers/${this.id}/topPic.png`
  icon = "logo-google"
  //customIcon = environment.root + 'assets/custom-icons/side-menu/contact-card.svg'
  site = "google.com"
  userPic = ''
  data: GoogleModel
  location: Location
  router: Router
  scopes = {                              //API interface constant from provider
    auth: 'openid https://www.googleapis.com/auth/plus.me https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
  }
  constructor(
    public profileService: ProfileService,
    public iab: InAppBrowser,
    location: Location,
    router: Router
  ) {
    super(
      profileService
    )
    this.location = location
    this.router = router
  }

  success = false
  getToken(token: string = '', callBack = (e) => { }) {
    this.success = false
    this.profileService.browserExtention.actionInProgress = true
    if (this.profileService.platform.isNative) {
      //Android model in Webview
      const browser = this.iab.create(`${environment.backend.url}/auth?provider=${this.id}&variation=loc&utoken=${token}&scope=${this?.scopes?.auth}`, '_blank', this.itabConf);
      browser.on('loadstart').subscribe(event => {
        let e = /token=([^\&]*)/.exec(event.url)
        let token = e && e.length > 0 ? e[1] : ''
        e = /google=([^\&]*)/.exec(event.url)
        let id = e && e.length > 0 ? e[1] : ''
        let error = /error=(.+)$/.exec(event.url);
        this.user = { uid: e && e.length > 0 ? id[1] : '' }
        console.log('url:', event.url, token, id, error)
        if (token) {
          this.auth = {
            token,
            id,
            tokenall: { ...event }
          }
          this.success = true
          this.store()
          this._loading = false
          this.profileService.browserExtention.actionInProgress = false
        }
        else if (error) callBack(error);
        if (token || error) browser.close()
      })
      browser.on('exit').subscribe(event => {
        console.log('Browser Exit fired')
        this.profileService.browserExtention.actionInProgress = false
        if (this.success)
          this.profileService.dlg.noticeUser('PROVIDERS.DATA_RECEIVED', { provider: this.name })
        else
          this.profileService.dlg.noticeUser('PROVIDERS.ACTION_CANCELED')
        browser.close()
      })
      browser.on('loaderror').subscribe(event => {
        let e = /token=([^\&]*)/.exec(event.url)
        let token = e && e.length > 0 ? e[1] : ''
        e = /google=([^\&]*)/.exec(event.url)
        let id = e && e.length > 0 ? e[1] : ''
        let error = /error=(.+)$/.exec(event.url);
        this.user = { uid: id }
        if (token) {
          this.auth = {
            token,
            id: id,
            tokenall: { ...event }
          }
          this.profileService.dlg.noticeUser('PROVIDERS.DATA_RECEIVED', { provider: this.name })
          this.store()
          this._loading = false
          this.profileService.browserExtention.actionInProgress = false
        }
        if (token || error) browser.close()
      })
    } else {
      window.location.href = `${environment.backend.url}/auth-web?provider=${this.id}&variation=srv&utoken=${token}&scope=${this?.scopes?.auth}`;
    }
  }

  saveToken(token: string, tokenall: Record<string, string>): void {
    this.auth = {
      token,
      id: tokenall?.orcid,
      tokenall,
      created: Utils.makeDate().toISOString()
    }
    this.getData()
  }

  getData() {
    this.profileService.browser.getData('https://www.googleapis.com/userinfo/v2/me?oauth_token=' + this.auth.token)
      .then((result: GoogleModel) => {
        console.log(result)
        this.data = result
        this.data.changed = Utils.makeDate().toISOString()
        this.changed = Utils.makeDate().toISOString()
        this.user = {
          uid: this.data.id,
          displayName: this.data.name,
          email: this.data.email,
          profileUrl: this.data.link || 'https://myaccount.google.com/profile',
          photoURL: this.data.picture
        }
        this.auth.id = this.data.id
        this._loading = false
        this.profileService.browserExtention.actionInProgress = false
        this.store(() => { })
        this.profileService.dlg.noticeUser('PROVIDERS.DATA_RECEIVED', { provider: this.name }).catch((e) => console.log('Error noticeUser', e))
      })
      .catch((e) => {
        // "status": "UNAUTHENTICATED" 
        console.log('e', e.error)
        if (e?.error?.code == 401) {
          this.profileService.dlg.showTransConfirmation(['PROFILE.LOGIN_REQUIRED'], {},
            () => {
              this._loading = false
              this.profileService.browserExtention.actionInProgress = false
              console.log('Error getData', e)
            },
            () => {
              this.getToken('')
            })
        }

      })
  }

  loadCompareModel = () => {
    let data = this.data
    if (data)
      this.compareFields = {
        ...{
          photoURL: this.data.picture,
          displayName: this.data.name,
          firstname: this.data.given_name,
          lastname: this.data.family_name,
          email: data.email,
        }
      }
  }
}